import { ThemeProvider } from "@emotion/react";
import { createTheme, Paper, Typography } from "@mui/material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from "@mui/material";
import React from "react";
import pyindexstring from "../../assets/images/pyindexstring.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./Code.css";

export const StringPython = () => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const defaultTheme = createTheme();
  const paperStyle = {
    p: 2,
    display: "flex",
    flexDirection: "column",
    height: "auto",
    backgroundColor: "#E6E6E6", // Set the background color to grey
    mt: 2,
  };
  return (
    <ThemeProvider theme={defaultTheme}>
      <Paper sx={paperStyle}>
        <Typography variant="h5">String in Python</Typography>
        <ul>
          <li>
            <Typography variant="h6">String in Python</Typography>
            <ul>
              <li>
                A string in Python is a way to store and represent a sequence of
                characters.
              </li>
              <li>It's like a collection of letters, numbers, and symbols.</li>
              <li>
                Once you create a string, you can't modify it; it's a fixed
                piece of data, and we call this property "immutable."
              </li>
              <li>
                Strings find use in various applications, especially in handling
                and manipulating textual information.
              </li>
              <li>
                They are commonly employed for tasks like storing and processing
                text data, representing things like names, addresses, and any
                other information that can be conveyed through text.
              </li>
            </ul>
          </li>
        </ul>
        <Typography variant="h6">Creating a String</Typography>
        <Typography variant="p">
          <b>Strings in Python</b> can be created using single quotes or double
          quotes or even triple quotes. Let us see how we can define a string in
          Python.
        </Typography>
        <Paper className="code">
          <pre>
            {`
# Python Program for 
# Creation of String 

# Creating a String 
# with single Quotes 
String1 = 'Welcome to Python'
print("String with the use of Single Quotes: ") 
print(String1) 

# Creating a String 
# with double Quotes 
String1 = "I'm a Graduate"
print("\nString with the use of Double Quotes: ") 
print(String1) 

# Creating a String 
# with triple Quotes 
String1 = '''"Hello, have a good day"'''
print("\n String with the use of Triple Quotes: ") 
print(String1) 

# Creating String with triple 
# Quotes allows multiple lines 
String1 = '''Python 
is 
Programming language'''
print("\nCreating a multiline String: ") 
print(String1) 
  `}
          </pre>
        </Paper>
        <Paper className="output">
          <Typography variant="h6">Output:</Typography>
          <pre>
            {`
String with the use of Single Quotes: 
Welcome to the Geeks World
String with the use of Double Quotes: 
I'm a Geek
String with the use of Triple Quotes: 
I'm a Geek and I live in a world of "Geeks"
Creating a multiline String: 
Geeks
            For
            Life                

`}
          </pre>
        </Paper>
        <Typography variant="h6">Accessing characters in Python</Typography>
        <ul>
          <li>
            <Typography variant="h6">Indexing in Python Strings</Typography>
            <ul>
              <li>
                In Python, individual characters of a string can be accessed
                using the method of Indexing.
              </li>
              <li>
                Indexing allows negative address references to access characters
                from the back of the string. For example, -1 refers to the last
                character, -2 refers to the second last character, and so on.
              </li>
              <li>
                While accessing an index out of the range will cause an
                IndexError.
              </li>
              <li>
                Only integers are allowed to be passed as an index; float or
                other types will cause a TypeError.
              </li>
            </ul>
          </li>
        </ul>
        <img src={pyindexstring} alt="pyindexstring" />
        <br />
        <Typography variant="h6">Updating Entire String</Typography>
        <Typography variant="p">
          As Python strings are immutable in nature, we cannot update the
          existing string. We can only assign a completely new value to the
          variable with the same name.
        </Typography>
        <Paper className="code">
          <pre>{`
# Python Program to Update 
# entire String 
  
String1 = "Hello, I'm a Programmer"
print("Initial String: ") 
print(String1) 
  
# Updating a String 
String1 = "Welcome to the Programming World"
print("\nUpdated String: ") 
print(String1)             
            `}</pre>
        </Paper>
        <Paper className="output">
          <pre>
            {`
Output: 

Initial String: 
Hello, I'm a Programmer
Updated String: 
Welcome to the programming World 
`}
          </pre>
        </Paper>
        <Typography variant="h6">
          Updating or deleting a single character
        </Typography>
        <Typography variant="p">
          Python strings are immutable, that means we cannot delete a character
          from it. When we try to delete thecharacter using the del keyword, it
          will generate an error.
        </Typography>
        <Paper className="code">
          <pre>
            {`
String1 = "Hello, I'm a Programmer"
print("Initial String: ") 
print(String1) 
  
print("Deleting character at 2nd Index: ") 
del String1[2] 
print(String1)
`}
          </pre>
        </Paper>
        <Paper className="output">
          <pre>
            {`
Output:

Initial String: 
Hello, I'm a Geek
Deleting character at 2nd Index: 
Traceback (most recent call last):
    del String1[2]
TypeError: 'str' object doesn't support item deletion                
`}
          </pre>
        </Paper>
        <br />
        <Typography variant="h6">Deleting Entire String</Typography>
        <Typography variant="p">
          Deletion of the entire string is possible with the use of del keyword.
          Further, if we try to print the string, this will produce an error
          because the String is deleted and is unavailable to be printed.
        </Typography>
        <Paper className="code">
          <pre>
            {`
# Python Program to Delete 
# entire String 

String1 = "Hello, I'm a Programmer"
print("Initial String: ") 
print(String1) 

# Deleting a String 
# with the use of del 
del String1 
print("\nDeleting entire String: ") 
print(String1) 
`}
          </pre>
        </Paper>
        <Paper className="output">
          <pre>
            {`Traceback (most recent call last): 
File "/home/test.py", line 12, in 
print(String1) 
NameError: name 'String1' is not defined `}
          </pre>
        </Paper>
        <Typography variant="h6">Formatting of Strings</Typography>
        <Typography variant="p">
          Strings in Python can be formatted with the use of format {"()"}{" "}
          method which is a very versatile and powerful tool for formatting
          Strings. Format method in String contains curly braces {"{}"} as
          placeholders which can hold arguments according to position or keyword
          to specify the order
        </Typography>
        <Paper className="code">
          <pre>
            {`
# Python Program for 
# Formatting of Strings 
  
# Default order 
String1 = "{} {} {}".format('Programming', 'For', 'Life') 
print("Print String in default order: ") 
print(String1) 
  
# Positional Formatting 
String1 = "{1} {0} {2}".format('Programming', 'For', 'Life') 
print("\nPrint String in Positional order: ") 
print(String1) 
  
# Keyword Formatting 
String1 = "{l} {f} {p}".format(p='Programming', f='For', l='Life') 
print("\nPrint String in order of Keywords: ") 
print(String1)`}
          </pre>
        </Paper>
        <Paper className="output">
          <pre>
            {`
Output: 

Print String in default order: 
Geeks For Life
Print String in Positional order: 
For Geeks Life
Print String in order of Keywords: 
Life For Geeks`}
          </pre>
        </Paper>
        <br />
        <br />
        <Typography variant="h4">Python String Built-in functions</Typography>
        <Grid mt={2}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="ascii-letters-content"
              id="ascii-letters-header"
            >
              <Typography>string.ascii_letters</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Concatenation of the ascii_lowercase and ascii_uppercase
                constants.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="ascii-lowercase-content"
              id="ascii-lowercase-header"
            >
              <Typography>string.ascii_lowercase</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>Concatenation of lowercase letters.</Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="ascii-uppercase-content"
              id="ascii-uppercase-header"
            >
              <Typography>string.ascii_uppercase</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>Concatenation of uppercase letters.</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="ascii-uppercase-content"
              id="ascii-uppercase-header"
            >
              <Typography>string.digit</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>Digit in strings.</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="hexdigits-content"
              id="hexdigits-header"
            >
              <Typography>string.hexdigits</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>Hexadigit in strings.</Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="letters-content"
              id="letters-header"
            >
              <Typography>string.letters</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Concatenation of the strings lowercase and uppercase.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="lowercase-content"
              id="lowercase-header"
            >
              <Typography>string.lowercase</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>A string must contain lowercase letters.</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary>
              <Typography variant="h6">string.octdigits</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>Octadigit in a string.</Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography variant="h6">string.punctuation</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                ASCII characters having punctuation characters.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography variant="h6">string.printable</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>String of characters which are printable.</Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography variant="h6">String.endswith()</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Returns True if a string ends with the given suffix; otherwise,
                returns False.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary>
              <Typography variant="h6">String.startswith()</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Returns True if a string starts with the given prefix;
                otherwise, returns False.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography variant="h6">String.isdigit()</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Returns “True” if all characters in the string are digits;
                otherwise, it returns “False”.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography variant="h6">String.isalpha()</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Returns “True” if all characters in the string are alphabets;
                otherwise, it returns “False”.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography variant="h6">string.isdecimal()</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Returns true if all characters in a string are decimal.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography variant="h6">str.format()</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                One of the string formatting methods in Python3, which allows
                multiple substitutions and value formatting.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="index-content"
              id="index-header"
            >
              <Typography>String.index</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Returns the position of the first occurrence of a substring in a
                string.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="uppercase-content"
              id="uppercase-header"
            >
              <Typography>string.uppercase</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>A string must contain uppercase letters.</Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="whitespace-content"
              id="whitespace-header"
            >
              <Typography>string.whitespace</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                A string containing all characters that are considered
                whitespace.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="swapcase-content"
              id="swapcase-header"
            >
              <Typography>string.swapcase()</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Method converts all uppercase characters to lowercase and vice
                versa of the given string and returns it.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="replace-content"
              id="replace-header"
            >
              <Typography>replace()</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Returns a copy of the string where all occurrences of a
                substring are replaced with another substring.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid mt={2} class="depricated">
          <Typography mt={2} mb={2} variant="h5">
            Deprecated String Functions
          </Typography>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="is-decimal-content"
              id="is-decimal-header"
            >
              <Typography>string.isdecimal()</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Returns true if all characters in a string are decimal.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="is-alnum-content"
              id="is-alnum-header"
            >
              <Typography>String.isalnum()</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Returns true if all the characters in a given string are
                alphanumeric.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="is-title-content"
              id="is-title-header"
            >
              <Typography>string.istitle()</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Returns True if the string is a title-cased string.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="partition-content"
              id="partition-header"
            >
              <Typography>String.partition()</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Splits the string at the first occurrence of the separator and
                returns a tuple.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="is-identifier-content"
              id="is-identifier-header"
            >
              <Typography>String.isidentifier()</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Check whether a string is a valid identifier or not.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary>
              <Typography variant="h6">String.len</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>Returns the length of the string.</Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography variant="h6">String.rindex</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Returns the highest index of the substring inside the string if
                the substring is found.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography variant="h6">String.Max</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Returns the highest alphabetical character in a string.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography variant="h6">String.min</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Returns the minimum alphabetical character in a string.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography variant="h6">String.splitlines</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>Returns a list of lines in the string.</Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography variant="h6">string.capitalize</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Return a word with its first character capitalized.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary>
              <Typography variant="h6">string.expandtabs</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Expand tabs in a string, replacing them by one or more spaces.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography variant="h6">string.find</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Return the lowest index of a substring in the string.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography variant="h6">string.rfind</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Find the highest index of a substring in the string.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography variant="h6">string.count</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Return the number of (non-overlapping) occurrences of a
                substring in the string.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography variant="h6">string.lower</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Return a copy of the string, but with uppercase letters
                converted to lowercase.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography variant="h6">string.split</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Return a list of the words of the string. If the optional second
                argument `sep` is absent or None.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography variant="h6">string.rsplit()</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Return a list of the words of the string, scanning the string
                from the end.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="rpartition-content"
              id="rpartition-header"
            >
              <Typography>string.rpartition()</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Method splits the given string into three parts.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="splitfields-content"
              id="splitfields-header"
            >
              <Typography>string.splitfields</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Return a list of the words of the string when only used with two
                arguments.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="join-content"
              id="join-header"
            >
              <Typography>string.join</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Concatenate a list or tuple of words with intervening
                occurrences of sep.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="strip-content"
              id="strip-header"
            >
              <Typography>string.strip()</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                It returns a copy of the string with both leading and trailing
                white spaces removed.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="lstrip-content"
              id="lstrip-header"
            >
              <Typography>string.lstrip</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Return a copy of the string with leading white spaces removed.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="rstrip-content"
              id="rstrip-header"
            >
              <Typography>string.rstrip</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Return a copy of the string with trailing white spaces removed.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary>
              <Typography variant="h6">string.swapcase</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Converts lowercase letters to uppercase and vice versa.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography variant="h6">string.translate</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>Translate the characters using a table.</Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography variant="h6">string.upper</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>Lowercase letters converted to uppercase.</Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography variant="h6">string.ljust</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>Left-justify in a field of given width.</Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography variant="h6">string.rjust</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>Right-justify in a field of given width.</Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography variant="h6">string.center()</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>Center-justify in a field of given width.</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary>
              <Typography variant="h6">string.zfill</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Pad a numeric string on the left with zero digits until the
                given width is reached.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography variant="h6">string.replace</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Return a copy of string s with all occurrences of substring old
                replaced by new.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography variant="h6">string.casefold()</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Returns the string in lowercase which can be used for caseless
                comparisons.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography variant="h6">string.encode</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Encodes the string into any encoding supported by Python. The
                default encoding is utf-8.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography variant="h6">string.maketrans</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Returns a translation table usable for str.translate().
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Typography mt = {2} mb={2} variant="h6">Advantages of Strings in Python:</Typography>
        <ul>
          <li>
            {" "}
           <b> Versatility:</b> Strings play a crucial role in various operations,
            including storing and manipulating text data. They are widely used
            for representing names, addresses, and other types of information
            that can be conveyed through text.
          </li>
          <li>
          <b>Rich Set of Methods:</b> Python offers a diverse set of string methods,
            providing flexibility in manipulating and working with strings.
            These methods simplify common tasks like converting strings to
            uppercase or lowercase, replacing substrings, and splitting strings
            into lists.
          </li>
          <li>
          <b>Immutable Nature:</b> Strings in Python are immutable, meaning once a
            string is created, it cannot be changed. This characteristic can be
            advantageous in certain scenarios, providing confidence that the
            value of a string will not unexpectedly alter.
          </li>
          <li>
          <b>Built-in Support:</b> Python inherently supports strings, eliminating
            the need to import additional libraries or modules for string
            operations. This built-in support simplifies the process of working
            with strings and reduces code complexity.
          </li>
          <li>
          <b> Concise Syntax:</b> Python offers a concise and expressive syntax for
            creating and manipulating strings. This feature makes it
            straightforward to write and read code that deals with strings,
            contributing to code readability and ease of use
          </li>
        </ul>
        <Typography variant="h5">
            String Slicing Operations
        </Typography>
        <Paper className="code">
            <pre>
                {`
# Original string
name = "Ahmedabad"

# Slicing from index 1 to 4 (exclusive)
slice_1 = name[1:4]
print("1. Slicing from index 1 to 4:", slice_1)  # Output: "hme"

# Slicing from the beginning to index 5 (exclusive)
slice_2 = name[:5]
print("2. Slicing from the beginning to index 5:", slice_2)  # Output: "Ahmed"

# Slicing from index 2 to the end
slice_3 = name[2:]
print("3. Slicing from index 2 to the end:", slice_3)  # Output: "medabad"

# Slicing the last 3 characters
slice_4 = name[-3:]
print("4. Slicing the last 3 characters:", slice_4)  # Output: "bad"

# Slicing with a step of 2
slice_5 = name[::2]
print("5. Slicing with a step of 2:", slice_5)  # Output: "Aeaabd"

# Reverse the string
slice_6 = name[::-1]
print("6. Reverse the string:", slice_6)  # Output: "dabademhA"
`}
            </pre>
        </Paper>
      </Paper>
    </ThemeProvider>
  );
};
