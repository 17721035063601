import { createTheme, Paper, Typography } from "@mui/material";
import React from "react";
import { ThemeProvider } from "styled-components";

export const SlicingList = () => {
  const defaultTheme = createTheme();
  const paperStyle = {
    p: 2,
    display: "flex",
    flexDirection: "column",
    height: "auto",
    backgroundColor: "#E6E6E6", // Set the background color to grey
    mt: 2,
  };
  return (
    <ThemeProvider theme={defaultTheme}>
      <Paper sx={paperStyle}>
        <Typography variant="p">
          We can get substrings and sublists using a slice. In Python List,
          there are multiple ways to print the whole list with all the elements,
          but to print a specific range of elements from the list, we use the
          Slice operation. Slice operation is performed on Lists with the use of
          a colon(:).
        </Typography>
        <ul>
          <li>
            To print elements from beginning to a range, use:{" "}
            <code>[: Index]</code>
          </li>
          <li>
            To print elements from end, use: <code>[:-Index]</code>
          </li>
          <li>
            To print elements from a specific Index till the end, use:{" "}
            <code>[Index:]</code>
          </li>
          <li>
            To print the whole list in reverse order, use: <code>[::-1]</code>
          </li>
        </ul>
        <Typography variant="h6">UNDERSTANDING SLICING OF LISTS:</Typography>
        <ul>
          <li>
            <code>pr[0]</code> accesses the first item, 2.
          </li>
          <li>
            <code>pr[-4]</code> accesses the fourth item from the end, 5.
          </li>
          <li>
            <code>pr[2:]</code> accesses [5, 7, 11, 13], a list of items from
            the third to last.
          </li>
          <li>
            <code>pr[:4]</code> accesses [2, 3, 5, 7], a list of items from the
            first to the fourth.
          </li>
          <li>
            <code>pr[2:4]</code> accesses [5, 7], a list of items from the third
            to the fifth.
          </li>
          <li>
            <code>pr[1::2]</code> accesses [3, 7, 13], alternate items, starting
            from the second item.
          </li>
        </ul>
        <Typography variant="h6">SLICING A LIST :</Typography>
        <Paper className="code">
            <pre>
{
    `
# Python program to demonstrate
# list slicing

# Creating a List

list = [1, 2, 3, 4, 5, 6]

# Print elements of a range
# using Slice operation
Sliced_List = list[3:8]
print(Sliced_List)

# Print elements from a
# pre-defined point to end
Sliced_List = list[5:]
print(Sliced_List)

# Printing elements from
# beginning till end
Sliced_List = list[:]
print(Sliced_List)

# Printing elements in reverse
# using Slice operation
Sliced_List = list[::-1]
print(Sliced_List)

# Creating a Multi-Dimensional List
# (By Nesting a list inside a List)
List = [['Java', 'Python'], ['C']]

# Print nested list
Sliced_List = List[:][0]
print(Sliced_List)

# Print elements from beginning
# to a pre-defined point using Slice
Sliced_List = List[:][1]
print(Sliced_List)

# Printing elements from
# beginning till end

Sliced_List = List[:]
print(Sliced_List)

# Printing elements in reverse
# using Slice operation
Sliced_List = List[::-1]
print(Sliced_List)

# Negative index List slicing

# Creating a List
List = ['P','Y','T','H','O','N']

# Print elements from beginning
# to a pre-defined point using Slice
Sliced_List = List[:-6]
print(Sliced_List)

# Print elements of a range
# using negative index List slicing
Sliced_List = List[-6:-1]
print(Sliced_List)

# Printing elements in reverse
# using Slice operation
Sliced_List = List[::-1]
print(Sliced_List)


output:

[4, 5, 6]
[6]
[1, 2, 3, 4, 5, 6]
[6, 5, 4, 3, 2, 1]
['Java', 'Python']
['C']
[['Java', 'Python'], ['C']]
[['Java', 'Python'], ['C']]
[]
['P']
['P', 'Y', 'T', 'H', 'O']
['N', 'O', 'H', 'T', 'Y', 'P']
    `
}
            </pre>
        </Paper>
      </Paper>
    </ThemeProvider>
  );
};
