import { createTheme, Paper, Typography } from "@mui/material";
import React from "react";
import { ThemeProvider } from "styled-components";

export const StringTask = () => {
  const defaultTheme = createTheme();
  const paperStyle = {
    p: 2,
    display: "flex",
    flexDirection: "column",
    height: "auto",
    backgroundColor: "#E6E6E6", // Set the background color to grey
    mt: 2,
  };
  return (
    <ThemeProvider theme={defaultTheme}>
      <Paper sx={paperStyle}>
        <Typography variant="h6" gutterBottom component="div">
          Practical Task on String :
        </Typography>
        <ul style={{ color: "blue" }}>
          <li>Count vowels in string (Easy)</li>
          <li>Count consonants in string (Easy)</li>
          <li>Count words in string (Easy)</li>
          <li>Reverse string (Easy)</li>
          <li>Check palindrome or not (Easy)</li>
          <li>Check string is anagram or not (Moderate)</li>
          <li>Remove duplicate characters from string (Moderate)</li>
          <li>Find duplicate characters from string (Moderate)</li>
          <li>Find duplicate words from string (Moderate)</li>
          <li>Find frequency of characters in string (Moderate)</li>
          <li>Find frequency of words in string (Moderate)</li>
          <li>Find first non-repeating character in string (Moderate)</li>
          <li>Find first non-repeating word in string (Moderate)</li>
          <li>Find all permutations of string (Hard)</li>
          <li>Find all combinations of string (Hard)</li>
          <li>Find all substrings of string (Hard)</li>
          <li>Find all palindromic substrings of string (Hard)</li>
          <li>Find all anagrams of string (Hard)</li>
          <li>Find all duplicate characters from string (Hard)</li>
          <li>Find all duplicate words from string (Hard)</li>
          <li>Find all unique characters from string (Hard)</li>
          <li>Find all unique words from string (Hard)</li>
        </ul>
      </Paper>
    </ThemeProvider>
  );
};
