import { createTheme, Paper, Typography } from "@mui/material";
import { ThemeProvider } from "@mui/system";
import React from "react";

export const TuplePython = () => {
  const defaultTheme = createTheme();
  const paperStyle = {
    p: 2,
    display: "flex",
    flexDirection: "column",
    height: "auto",
    backgroundColor: "#E6E6E6", // Set the background color to grey
    mt: 2,
  };
  return (
    <div>
      <ThemeProvider theme={defaultTheme}>
        <Paper sx={paperStyle}>
          <Typography variant="h4">Tuple :</Typography>
          <Typography variant="h6">Characteristics of Tuple :</Typography>
          <ul>
            <li>Tuples are immutable.</li>
            <li>Tuples are ordered.</li>
            <li>Tuples can contain any number of elements.</li>
            <li>Tuples can contain different data types.</li>
            <li>Tuples can be nested.</li>
            <li>Tuples can be accessed by index.</li>
            <li>Tuples are faster than lists.</li>
            <li>Tuples can be used as keys in dictionaries.</li>
            <li>Tuple can create using () </li>
            <li>Data type of tuple is tuple</li>
          </ul>
          <Typography variant="h6">Creating a Tuple :</Typography>
          <Paper className="code">
            <pre>
              {`

# Creating an empty Tuple
Tuple1 = ()
print("Initial empty Tuple: ")
print(Tuple1)
 
# Creating a Tuple
# with the use of string
Tuple1 = ('Geeks', 'For')
print("\nTuple with the use of String: ")
print(Tuple1)
 
# Creating a Tuple with
# the use of list
list1 = [1, 2, 4, 5, 6]
print("Tuple using List: ")
print(tuple(list1))
 
# Creating a Tuple
# with the use of built-in function
Tuple1 = tuple('Python')
print("Tuple with the use of function: ")
print(Tuple1)                        

output:
Initial empty Tuple: 
()

Tuple with the use of String: 
('Geeks', 'For')

Tuple using List: 
(1, 2, 4, 5, 6)

Tuple with the use of function: 
('P', 'y', 't', 'h', 'o', 'n') 
                        `}
            </pre>
          </Paper>
          <Typography variant="h6">Accessing of Tuple :</Typography>
          <Paper className="code">
            <pre>
              {`
# Accessing Tuple with Indexing
Tuple1 = tuple("Python")
print("First element of Tuple: ")
print(Tuple1[1])

# Accessing Tuple with Negative Indexing

Tuple1 = tuple("Python")
print("Last element of Tuple: ")
print(Tuple1[-1])

output:

First element of Tuple: 
y

Last element of Tuple:
n
`}
            </pre>
          </Paper>
          <Typography variant="h6">What is Immutable in Tuples? :</Typography>
          <ul>
            <li>
              Tuples in Python are similar to Python lists but not entirely.
            </li>
            <li>
              Tuples are immutable and ordered and allow duplicate values.
            </li>
            <li>
              We can find items in a tuple since finding any item does not make
              changes in the tuple.
            </li>
            <li>One cannot add items to a tuple once it is created.</li>
            <li>Tuples cannot be appended or extended.</li>
            <li>We cannot remove items from a tuple once it is created.</li>
          </ul>
          <Paper className="code">
            <pre>
              {`
mytuple = (1, 2, 3, 4, 5)
 
# tuples are indexed
print(mytuple[1])
print(mytuple[4])
 
# tuples contain duplicate elements
mytuple = (1, 2, 3, 4, 2, 3)
print(mytuple)
 
# adding an element
mytuple[1] = 100
print(mytuple)

output:
2
5
(1, 2, 3, 4, 2, 3)
Traceback (most recent call last):
  File "e0eaddff843a8695575daec34506f126.py", line 11, in
    tuple1[1] = 100
TypeError: 'tuple' object does not support item assignment
                `}
            </pre>
          </Paper>
          <Typography variant="h6">Concatenation of Tuples :</Typography>
          <Paper className="code">
            <pre>
              {`
tuple1 = (0, 1, 2, 3)
tuple2 = ('python', 'java')
 
# Concatenating above two
print(tuple1 + tuple2)   

output:
(0, 1, 2, 3, 'python', 'java')
                `}
            </pre>
          </Paper>
          <Typography variant="h6">Nesting of Python Tuples :</Typography>
          <Paper className="code">
            <pre>
              {`
# Code for creating nested tuples
tuple1 = (0, 1, 2, 3)
tuple2 = ('python', 'geek')
 
tuple3 = (tuple1, tuple2)
print(tuple3)

output:
((0, 1, 2, 3), ('python', 'geek'))
                `}
            </pre>
          </Paper>
          <Typography variant="h6">Repetition Python Tuples :</Typography>

          <Paper className="code">
            <pre>
              {`
# Code to create a tuple with repetition
tuple3 = ('python',)*3
print(tuple3)

output:
('python', 'python', 'python')
                `}
            </pre>
          </Paper>
          <Typography variant="h6">Slicing of Tuple :</Typography>
          <Paper className="code">
            <pre>
              {`
# code to test slicing
tuple1 = (0 ,1, 2, 3)

print(tuple1[1:])
print(tuple1[::-1])
print(tuple1[2:4])

output:
(1, 2, 3)
(3, 2, 1, 0)
(2, 3)
                `}
            </pre>
          </Paper>
          <Typography variant="h6">Deleting a Tuple :</Typography>
          <Paper className="code">
            <pre>
              {`
# Code for deleting a tuple
tuple3 = ( 0, 1)
 
del tuple3
print(tuple3)

output:
Traceback (most recent call last):
NameError: name 'tuple3' is not defined
                `}
            </pre>
          </Paper>
          <Typography variant="h6">
            Finding the Length of a Python Tuple :
          </Typography>
          <Paper className="code">
            <pre>
              {`
# Code for printing the length of a tuple
tuple2 = ('python', 'java')
print(len(tuple2))       

output:
2
                `}
            </pre>
          </Paper>
          <Typography variant="h6">Tuples in a Loop :</Typography>
          <Paper className="code">
            <pre>
              {`
# python code for creating tuples in a loop
data =("java","python","c","c++")
for i in data:
    print(i)

output:
java
python
c
c++    
`}
            </pre>
          </Paper>
          <Typography variant="h6">Converting a List to a Tuple :</Typography>
          <Paper className="code">
            <pre>
              {`
ListData = [1, 2, 3, 4, 5]
tupleData = tuple(ListData)
print(tupleData)
print(type(tupleData))

output:
(1, 2, 3, 4, 5)
<class 'tuple'>
                `}
            </pre>
          </Paper>
          <Typography variant="h6">Converting a Tuple to a List :</Typography>
          <Paper className="code">
            <pre>
              {
                `
tupleData = (1, 2, 3, 4, 5)
ListData = list(tupleData)
print(ListData)
print(type(ListData))

output:
[1, 2, 3, 4, 5]
<class 'list'>
                `
              }
            </pre>
          </Paper>
        </Paper>
      </ThemeProvider>
    </div>
  );
};
