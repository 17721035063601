import { createTheme, Paper, ThemeProvider, Typography } from "@mui/material";
import React from "react";

export const FunctionPythonNested = () => {
  const defaultTheme = createTheme();
  const paperStyle = {
    p: 2,
    display: "flex",
    flexDirection: "column",
    height: "auto",
    backgroundColor: "#E6E6E6", // Set the background color to grey
    mt: 2,
  };
  return (
    <ThemeProvider theme={defaultTheme}>
      <Paper sx={paperStyle}>
        <Typography variant="h4">Python Inner function</Typography>
        <p>
          In Python, functions are considered first-class objects, which means
          they are treated in a versatile way. They can be stored in data
          structures, passed as arguments to other functions, or used in control
          structures like loops and conditionals. When a programming language
          supports first-class functions, it means that functions are treated as
          flexible entities that can be manipulated in various ways. Python
          embraces this concept, allowing functions to be used and manipulated
          in a manner similar to other data types.
        </p>
      </Paper>
    </ThemeProvider>
  );
};
