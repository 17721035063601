import { createTheme, Paper, Typography } from "@mui/material";
import { ThemeProvider } from "@mui/system";
import React from "react";

export const ListPython = () => {
  const defaultTheme = createTheme();
  const paperStyle = {
    p: 2,
    display: "flex",
    flexDirection: "column",
    height: "auto",
    backgroundColor: "#E6E6E6", // Set the background color to grey
    mt: 2,
  };
  return (
    <ThemeProvider theme={defaultTheme}>
      <Paper sx={paperStyle}>
        <Typography variant="h5">List in Python</Typography>
        <Typography variant="p">
          Python Lists are just like dynamically sized arrays, declared in other
          languages (vector in C++ and ArrayList in Java). In simple language, a
          list is a collection of things, enclosed in [ ] and separated by
          commas.
        </Typography>
        <Typography variant="h6">Characteristics of List :</Typography>
        <ul>
          <li>List is ordered.</li>
          <li>List Stores Data in indexed form.</li>
          <li>List is mutable.</li>
          <li>List is dynamic. [no need to specify size]</li>
          <li>List Allows Duplicate Values.</li>
          <li>List is Heterogeneous. [i.e. we can store any type of data]</li>
          <li>List is a sequence of elements.</li>
          <li>List is iterable.</li>
          <li>To create list in python, we use [ ].</li>
          <li>Data type of list is list.</li>
        </ul>
        <Paper className="code">
          <pre>
            {`

# Python program to demonstrate
# Creation of List
 
# Creating a List
List = []
print("Blank List: ")
print(List)
 
# Creating a List of numbers
List = [100, 200, 149]
print("List of numbers: ")
print(List)
 
# Creating a List of strings and accessing
# using index
List = ["Python", "Is", "Programming", "Language"]
print("List Items: ")
print(List[0])
print(List[2])       

Output:

Blank List:
[]
List of numbers:
[100, 200, 149]
List Items:
Python
Programming

                `}
          </pre>
        </Paper>
        <Typography variant="h6">
          Creating a list with multiple distinct or duplicate elements
        </Typography>
        <Paper className="code">
          <pre>{`


# Creating a List with
# the use of Numbers
# (Having duplicate values)
List = [1, 2, 4, 4, 3, 3, 3, 6, 5]
print("List with the use of Numbers: ")
print(List)
 
# Creating a List with
# mixed type of values
# (Having numbers and strings)
List = [1, 22, 'Python', 4, 'Code', 4, 'Python']
print("List with the use of Mixed Values: ")
print(List)            

Output:
List with the use of Numbers: 
[1, 2, 4, 4, 3, 3, 3, 6, 5]

List with the use of Mixed Values:
[1, 22, 'Python', 4, 'Code', 4, 'Python']

            `}</pre>
        </Paper>
        <Typography variant="h6">Accessing elements from the List</Typography>
        <Paper className="code">
          <pre>
            {`
# Python program to demonstrate
# accessing of element from list

# Creating a List with
# the use of multiple values
List = ["Java", "Python", "C"]

# accessing a element from the
# list using index number
print("Accessing a element from the list")
print(List[0])
print(List[2])

Output:
Accessing a element from the list
Java
C

                    `}
          </pre>
        </Paper>
        <Typography variant="h6">
          Accessing elements from a multi-dimensional list
        </Typography>
        <Paper className="code">
          <pre>
            {`
# Python program to demonstrate
# accessing of element from list

# Creating a Multi-Dimensional List
# (By Nesting a list inside a List)
List = [['Python', 'Java'], ['C++']]

# accessing a element from the
# Multi-Dimensional List using
# index number
print("Acessing a element from a Multi-Dimensional list")
print(List[0][1])
print(List[1][0])

Output:
Acessing a element from a Multi-Dimensional list
Java
C++
`}
          </pre>
        </Paper>
        <Typography variant="h6">Negative indexing in lists</Typography>
        <Paper className="code">
          <pre>
            {`

# Python program to demonstrate
# Negative indexing in lists

# Creating a List
List = ['P','Y','T','H','O','N']

# accessing a element using
# negative indexing

# print the last element of list
print("Last element of list")
print(List[-1])

# print the third last element of list
print("Third last element of list")
print(List[-3])

Output:
Last element of list
N
Third last element of list
H
`}
          </pre>
        </Paper>
        <Typography variant="h6">Getting the size of list In Python</Typography>
        <Paper className="code">
          <pre>
            {`


# Creating a List
List1 = []
print(len(List1))
 
# Creating a List of numbers
List2 = [10, 20, 14]
print(len(List2))                        
                        `}
          </pre>
        </Paper>
        <Typography variant="h5">List built in Functions:</Typography>
        <Typography variant="h6" mt={1}>
          append() method :
          <ul>
            <li>
              Elements can be added to the List by using the built-in{" "}
              <code>append()</code> function.
            </li>
            <li>
              Only one element at a time can be added to the list by using the{" "}
              <code>append()</code> method.
            </li>
            <li>
              For the addition of multiple elements with the{" "}
              <code>append()</code> method, loops are used.
            </li>
            <li>
              Tuples can also be added to the list with the use of the{" "}
              <code>append</code> method because tuples are immutable.
            </li>
            <li>
              Unlike Sets, Lists can also be added to the existing list with the
              use of the <code>append()</code> method.
            </li>
          </ul>
        </Typography>
        <Paper className="code">
          <pre>
            {`
# Python program to demonstrate
# Addition of elements in a List

# Creating a List
List = []
print("Initial blank List: ")
print(List)

# Addition of Elements
# in the List
List.append(1)
List.append(2)
List.append(4)
print("List after Addition of Three elements: ")

print(List)

# Adding elements to the List
# using Iterator
for i in range(1, 4):
    List.append(i)

print("List after Addition of elements from 1-3: ")
print(List)

# Adding Tuples to the List
List.append((5, 6))
print("List after Addition of a Tuple: ")
print(List)


output:

Initial blank List:
[]
List after Addition of Three elements:
[1, 2, 4]
List after Addition of elements from 1-3:
[1, 2, 4, 1, 2, 3]
List after Addition of a Tuple:
[1, 2, 4, 1, 2, 3, (5, 6)]

`}
          </pre>
        </Paper>
        <Typography variant="h6" mt={1}>
          insert() method :
        </Typography>
        <Paper className="code">
          <pre>
            {`
List = [1,2,3,4,5]  

# Insert at index 2 value 6
List.insert(2,6)
print(List)

output:
[1, 2, 6, 3, 4, 5]
    `}
          </pre>
        </Paper>
        <Typography variant="h6" mt={1}>
          extend() method :
        </Typography>
        <Paper className="code">
          <pre>
            {`
# Python program to demonstrate

List = [1,2,3,4,5]

list.extend([6,7,8,9,10])

print(List)

output:
[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
                        `}
          </pre>
        </Paper>
        <Typography variant="h6" mt={1}>
          Reversing a List :
        </Typography>
        <Paper className="code">
          <pre>
            {`
# Reversing a list
mylist = [1, 2, 3, 4, 5, 'Geek', 'Python']
mylist.reverse()
print(mylist)    
    `}
          </pre>
        </Paper>
        <Typography variant="h6" mt={1}>
          Removing Elements from the List :
        </Typography>
        <Paper className="code">
          <pre>
            {`

# Python program to demonstrate
# Removal of elements in a List
 
# Creating a List
List = [1, 2, 3, 4, 5, 6,
        7, 8, 9, 10, 11, 12]
print("Initial List: ")
print(List)
 
# Removing elements from List
# using Remove() method
List.remove(5)
List.remove(6)
print("\nList after Removal of two elements: ")
print(List)                
                `}
          </pre>
        </Paper>
        <Typography variant="h6" mt={1}>
          Remove Using pop() method
        </Typography>
        <Paper className="code">
          <pre>
            {`
List = [1, 2, 3, 4, 5]
 
# Removing element from the
# Set using the pop() method
List.pop()
print("\nList after popping an element: ")
print(List)
 
# Removing element at a
# specific location from the
# Set using the pop() method
List.pop(2)
print("\nList after popping a specific element: ")
print(List)

outout:
List after popping an element: 
[1, 2, 3, 4]

List after popping a specific element: 
[1, 2, 4]
                    `}
          </pre>
        </Paper>
        <Typography variant="h6" mt={1}>
          List count() method:
        </Typography>
        <Paper className="code">
          <pre>
            {`
List = [1, 2, 3, 4, 5, 1, 1, 1, 4, 5]

# Counting the occurrence of
# element '1' in the list
cnt = List.count(1)

# printing the occurrence

print("The count of 1 is:", cnt)

output:
The count of 1 is: 4
                     `}
          </pre>
        </Paper>
        <Typography variant="h6" mt={1}>
          List index() method:
        </Typography>
        <Paper className="code">
          <pre>
            {`
List = [1, 2, 3, 4, 5, 1, 1, 1, 4, 5]

# finding index of element 5

ind = List.index(5)
print("The index of element 5:", ind)

output:
The index of element 5: 4
                     `}
          </pre>
        </Paper>
        <Typography variant="h6" mt={1}>
            List sort() method:
        </Typography>
        <Paper className="code">
            <pre>
                {
                    `
data = [10,20,30,33,11,21]                    

data.sort()
print("after sorting data :")
print(data)

#Descending order
print("after sorting data decending:")
data.sort(reverse=True)


output:
after sorting data
[10, 11, 20, 21, 30, 33]

after sorting data decending:
[33, 30, 21, 20, 11, 10]
                    `
                }
            </pre>
        </Paper>
      </Paper>
    </ThemeProvider>
  );
};
