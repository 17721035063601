import { ThemeProvider } from "@emotion/react";
import {
  Accordion,
  AccordionSummary,
  Box,
  createTheme,
  Grid,
  Paper,
  Tab,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Grade } from "@mui/icons-material";
import "./Code.css";

export const ForLoopPython = () => {
  const defaultTheme = createTheme();
  const [tabvalue, settabvalue] = useState("1");
  const handleChange = (event, newValue) => {
    settabvalue(newValue);
  };
  const paperStyle = {
    p: 2,
    display: "flex",
    flexDirection: "column",
    height: "auto",
    backgroundColor: "#E6E6E6", // Set the background color to grey
    mt: 2,
  };
  return (
    <ThemeProvider theme={defaultTheme}>
      <Paper sx={paperStyle}>
        <Typography variant="h6">For Loop in Python</Typography>
        <Typography variant="p">
          Python For loop is used for sequential traversal i.e. it is used for
          iterating over an iterable like String, Tuple, List, Set, or
          Dictionary.
        </Typography>

        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          {/* <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="For Loop" value="1" />
              <Tab label="For Loop with else" value="2" />
              <Tab label="Nested For Loop" value="3" />
            </TabList> */}
          <Typography variant="p">
            {/* <TabPanel value="1"> */}
            <Typography variant="h6">For Loop</Typography>
            <Typography variant="p">
              The for loop in Python is used to iterate over a sequence (list,
              tuple, string) or other iterable objects. Iterating over a
              sequence is called traversal. When to use for loop: <br />
              <ul>
                <li>
                  When we have a block of code which we want to repeat a fixed
                  number of times, then we use the for loop.
                </li>
                <li>
                  When we have a block of code which we want to repeat as long
                  as a condition is true,
                </li>
              </ul>
            </Typography>
            {/* </TabPanel> */}
          </Typography>
          <Typography variant="h6">For loop With range() function</Typography>
          <br />
          <Grid>
            <Typography variant="h7">
              range(10) function with 1 argument :
              <br />
              <Paper className="code">
                <pre>
                  {`
for i in range(10):
    print(i,end=" ")

# loop will start from 0 and end at 9                                

output : 0 1 2 3 4 5 6 7 8 9
`}
                </pre>
              </Paper>
            </Typography>
            <Typography variant="h7">
              range(1,11) function with 2 argument :<br />
              First argument is starting point and <br /> second argument is
              ending point
              <br />
              <Paper className="code">
                <pre>
                  {`
for i in range(10):
    print(i,end=" ")

# loop will start from 1 and end at 10                                

output : 1 2 3 4 5 6 7 8 9 10
`}
                </pre>
              </Paper>
            </Typography>
            <Typography variant="h7">
              range(1,20,2) function with 3 argument :<br />
              First argument is starting point and <br /> second argument is
              ending point and <br />
              third argument is step size
              <br />
              <Paper className="code">
                <pre>
                  {`
for i in range(1,20,2):
    print(i,end=" ")

# loop will start from 0 and end at 9                                

output : 1 3 5 7 9 11 13 15 17 19
`}
                </pre>
              </Paper>
            </Typography>
          </Grid>
        </Box>
        <Typography variant="h6">Python For Loop inside a For Loop</Typography>
        <br />
        <Grid>
          <ul>
            <li>This code uses nested for loops.</li>
            <li>
              It iterates over two ranges of numbers (1 to 3 inclusive) using
              the variables i and j.
            </li>
            <li>
              For each combination of the two loops, the code prints the values
              of i and j.
            </li>
            <li>
              The inner loop is executed for each value of i in the outer loop.
            </li>
            <li>
              The output of this code will print the numbers from 1 to 3 three
              times.
            </li>
            <li>
              This is because each value of i is combined with each value of j
              in the nested loops.
            </li>
          </ul>
          <Paper className="code">
            <pre>
              {`
for i in range(1, 4):
	for j in range(1, 4):
		print(i, j)

output :

1 1
1 2
1 3
2 1
2 2
2 3
3 1
3 2
3 3
`}
            </pre>
          </Paper>
        </Grid>
        <Typography variant="h6">Python For Loop with else</Typography>
        <Typography variant="p">
          Python also allows us to use the else condition for loops. The else
          block just after for/while is executed only when the loop is NOT
          terminated by a break statement.
        </Typography>
        <Paper className="code">
            <pre>
                {
                    `
# Python program to demonstrate
# for-else loop

for i in range(1, 4):
	print(i)
else: # Executed because no break in for
	print("No Break")`}
            </pre>
        </Paper>
      </Paper>
    </ThemeProvider>
  );
};
