import { createTheme, Paper, Typography } from "@mui/material";
import React from "react";
import { ThemeProvider } from "styled-components";
import "./Code.css";
import ifstmt from "../../assets/images/ifstmt.jpg";

export const IfStatementPython = () => {
  const defaultTheme = createTheme();
  const paperStyle = {
    p: 2,
    display: "flex",
    flexDirection: "column",
    height: "auto",
    backgroundColor: "#E6E6E6", // Set the background color to grey
    mt: 2,
  };
  return (
    <ThemeProvider theme={defaultTheme}>
      <Paper sx={paperStyle}>
        <Typography variant="h6">Types of Control Flow in Python</Typography>
        <ul style={{ color: "blue" }}>
          <li>
            In Python programming language, the type of control flow statements
            is as follows:
          </li>

          <li>The if statement</li>
          <li>The if-else statement</li>
          <li>The nested-if statement</li>
          <li>The if-elif-else ladder</li>
        </ul>
        <Typography variant="h6">The if statement :</Typography>
        <Typography variant="p">
          The if statement is the most simple decision-making statement. It is
          used to decide whether a certain statement or block of statements will
          be executed or not.
        </Typography>
        <Paper className="code">
          <pre>
            {`if condition:
   # Statements to execute if
   # condition is true`}
          </pre>
        </Paper>
        <Typography variant="p">
          Here, the condition after evaluation will be either true or false. if
          the statement accepts boolean values – if the value is true then it
          will execute the block of statements below it otherwise not. <br />
          As we know, python uses indentation to identify a block. So the block
          under an if statement will be identified as shown in the below
          example:
        </Typography>
        <Paper className="code">
          <pre>
            {`if condition:
   statement1
statement2

# Here if the condition is true, if block 
# will consider only statement1 to be inside 
# its block.`}
          </pre>
        </Paper>
        <Paper>
          <img src={ifstmt} alt="ifstatement" />
        </Paper>
        <Typography variant="h6">Example if-else statement :</Typography>
        <Paper className="code">
          <pre>
            {`# Python program to illustrate
no = 10
if no % 2 == 0:
    print("Even")
else:
    print("Odd")
    
output:
Even    
`}
          </pre>
        </Paper>
        <Typography variant="h6">Example of nested if statement :</Typography>
        <Paper className="code">
          <pre>
            {`
# Python program to illustrate
no = 10
if i % 2 ==0:
    if i % 3 == 0:
        print("Divisible by 3 and 2")
    else:
        print("Divisible by 2 not divisible by 3")
else:
    print("not Divisible by 2")                                

output:
Divisible by 2 not divisible by 3
`}
          </pre>
        </Paper>
        <Typography variant="h6">Example of if-elif-else ladder :</Typography>
        <Typography variant="p">
          Here, a user can decide among multiple options. The if statements are
          executed from the top down. As soon as one of the conditions
          controlling the if is true, the statement associated with that if is
          executed, and the rest of the ladder is bypassed. If none of the
          conditions is true, then the final else statement will be executed.
        </Typography>
        <Paper className="code">
          <pre>
            {`
# Python program to illustrate if-elif-else ladder
#!/usr/bin/python
 
i = 20
if (i == 10):
    print("i is 10")
elif (i == 15):
    print("i is 15")
elif (i == 20):
    print("i is 20")
else:
    print("i is not present")                
                `}
          </pre>
        </Paper>
        <Typography variant="h6">Short Hand if statement :</Typography>
        <Typography variant="p">
          Whenever there is only a single statement to be executed inside the if
          block then shorthand if can be used. The statement can be put on the
          same line as the if statement.
        </Typography>
        <Paper className="code">
          <pre>
            {`
# Python program to illustrate short hand if
i = 10
if i < 15: print("i is less than 15")   

output:
i is less than 15

`}
          </pre>
        </Paper>
        <Typography variant="h6">Short Hand if-else statement :</Typography>
        <Typography variant="p">
          In the given example, we are printing True if the number is 15 else it
          will print False.
        </Typography>
        <Paper className="code">
            <pre>
{`
# Python program to illustrate short hand if-else
i = 10
print(True) if i < 15 else print(False)
`}                
            </pre>
        </Paper>
      </Paper>
    </ThemeProvider>
  );
};
