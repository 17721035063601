import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PeopleIcon from "@mui/icons-material/People";
import BarChartIcon from "@mui/icons-material/BarChart";
import LayersIcon from "@mui/icons-material/Layers";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { Collapse, ListItem } from "@mui/material";
//import { Link } from "react-router-dom";
//import link as routerlink
import { Link as RouterLink } from "react-router-dom";

const mainListItemsData = [
  {
    text: "Introduction",
    icon: <DashboardIcon />,
    link: "introduction",
    style: { textDecoration: "none", color: "black" },
  },
  {
    text: "datatype",
    icon: <PeopleIcon />,
    link: "datatype",
    style: { textDecoration: "none", color: "black" },
  },
  {
    text: "Input-output",
    icon: <ShoppingCartIcon />,
    link: "inputoutput",
    style: { textDecoration: "none", color: "black" },
  },

  {
    text: "Operators",
    icon: <BarChartIcon />,
    link: "operators",
    style: { textDecoration: "none", color: "black" },
  },
  {
    text: "String",
    icon: <LayersIcon />,
    link: "strings",
    style: { textDecoration: "none", color: "black" },
    subitems: [
      { text: "SubItem 1", link: "strings/task" },
      { text: "SubItem 2", link: "strings/subitem2" },
    ],
  },
];
export const mainListItems = (
  <React.Fragment>
    {mainListItemsData.map((res, index) => (
      <ListItem
        key={res.text}
        disablePadding
        component={RouterLink}
        to={res.link}
        style={{ marginTop: "10px" }}
      >
        <ListItemButton>
          <ListItemIcon>{res.icon}</ListItemIcon>
          <ListItemText primary={res.text} />
        </ListItemButton>
      </ListItem>
    ))}
  </React.Fragment>
);
