import { ThemeProvider } from "@emotion/react";
import { createTheme, Paper, Typography } from "@mui/material";
import React from "react";

export const DictonryPython = () => {
  const paperStyle = {
    p: 2,
    display: "flex",
    flexDirection: "column",
    height: "auto",
    backgroundColor: "#E6E6E6", // Set the background color to grey
    mt: 2,
  };
  const defaultTheme = createTheme();
  return (
    <ThemeProvider theme={defaultTheme}>
      <Paper sx={paperStyle}>
        <Typography variant="h6">Dictionary in Python</Typography>
        <Typography variant="p">
          In Python, a dictionary is a data structure that allows you to store
          and organize data in the form of key-value pairs. Each element in a
          dictionary consists of a key and its corresponding value. Think of it
          like a real-world dictionary where words (keys) have definitions
          (values). Unlike some other data types in Python, dictionaries
          uniquely associate each key with a single value.
        </Typography>
        <Typography variant="h6">Python Dictionary Syntax</Typography>
        <Paper className="code">
          <pre>
            {`
dict_var = {key1 : value1, key2 : value2, …..}            
            `}
          </pre>
        </Paper>
        <Typography variant="h6">Example of Dictionary in Python </Typography>
        <Typography variant="p">
          The dictionary holds the A dictionarykey: value pair. Key-value is
          provided in the dictionary to make it more optimized. The code defines
          a Python dictionary named Dict with three key-value pairs. Each key is
          an integer, and each value is a string. The code then prints the
          contents of the dictionary. The output shows that the dictionary
        </Typography>
        <Paper className="code">
          <pre>
{`
Dict = {1: 'Java', 2: 'Python', 3: 'Cpp'}
print(Dict)

# Output: {1: 'Java', 2: 'Python', 3: 'Cpp'}
 `}
          </pre>
        </Paper>
      </Paper>
    </ThemeProvider>
  );
};
