import { createTheme, Paper, Typography } from "@mui/material";
import React from "react";
import { ThemeProvider } from "styled-components";
import Header from "../../Layouts/Header";

export const ControlFlowPython = () => {
  const defaultTheme = createTheme();
  const paperStyle = {
    p: 2,
    display: "flex",
    flexDirection: "column",
    height: "auto",
    backgroundColor: "#E6E6E6", // Set the background color to grey
    mt: 2,
  };
  return (
    
    <ThemeProvider theme={defaultTheme}>
      
      <Paper sx={paperStyle}>
        <Typography variant="h4">Control Flow</Typography>
        <Typography variant="p">
          The control flow of a program refers to the sequence in which the code
          of the program is executed. In Python, this control flow is managed by
          using conditional statements, loops, and function calls. This part
          specifically addresses the if statement and both the for and while
          loops. The discussion about functions is reserved for later in this
          chapter. Additionally, the control flow can be influenced by the
          raising and handling of exceptions
        </Typography>
        <ul>
          <ul>
            <li>
              In real-life scenarios, we often encounter situations where we
              need to make decisions.
            </li>
            <li>
              Based on these decisions, we determine the next course of action.
            </li>
            <li>
              Similarly, in programming, we face situations where decisions need
              to be made.
            </li>
            <li>
              Based on these decisions, we execute the next set of instructions.
            </li>
            <li>
              Conditional statements in the Python language play a crucial role
              in determining the direction or flow of program execution.
            </li>
          </ul>
        </ul>
      </Paper>
    </ThemeProvider>
  );
};
