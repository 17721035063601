import {
Accordion,
  AccordionDetails,
  AccordionSummary,
  createTheme,
  Grid,
  Paper,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./Code.css";
import dt from "../../assets/images/pydttype.png";

export const DataTypesPython = () => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const defaultTheme = createTheme();
  const paperStyle = {
    p: 2,
    display: "flex",
    flexDirection: "column",
    height: "auto",
    backgroundColor: "#E6E6E6", // Set the background color to grey
    mt: 2,
  };
  return (
    <ThemeProvider theme={defaultTheme}>
      <Paper sx={paperStyle}>
        <ul>
          <li>
            <Typography variant="h6">Data Types in Python</Typography>
            <ul>
              <li>
                Data types are like categories or groups for different pieces of
                information.
              </li>
              <li>
                They help us understand what kind of values we're working with
                and what we can do with those values.
              </li>
              <li>
                In Python programming, everything is treated as an object.
              </li>
              <li>
                So, when we talk about data types, we're essentially talking
                about classes.
              </li>
              <li>
                Variables, on the other hand, are instances or examples of these
                classes.
              </li>
              <li>
                They hold specific pieces of information that belong to a
                certain category.
              </li>
            </ul>
          </li>
        </ul>
        <Paper sx={paperStyle}>
          <ul>
            <li>
              <Typography variant="h6">Built-in Python Data Types</Typography>
              <ul>
                <li>Numeric</li>
                <li>Text Type</li>
                <li>
                  Sequence Type
                  <ul>
                    <li>Python list</li>
                    <li>Python tuple</li>
                    <li>Python range</li>
                  </ul>
                </li>
                <li>Boolean</li>
                <li>Set</li>
                <li>Dictionary</li>
              </ul>
            </li>
          </ul>
          <Paper sx={paperStyle}>
            <img src={dt} alt="data type" />
          </Paper>

          <Typography variant="h6">Numeric Data Type</Typography>

          <Typography variant="p">
            Number data types store numeric values. They are immutable data
            types, which means that changing the value of a number data type
            results in a newly allocated object.
          </Typography>
          <br />
          <Typography variant="p">
            Different types of Number data types are
          </Typography>
          <ul>
            <li>int (signed integers)</li>
            <li>float (floating point real values)</li>
            <li>complex (complex numbers)</li>
          </ul>

          <Typography variant="h6">Python Int type</Typography>
          <Typography variant="p">
            Python int is the whole number, including negative numbers but not
            fractions. In Python, there is no limit to how long an integer value
            can be.
          </Typography>
          <Paper className="code">
            num = -8 <br /># print the data type <br />
            print(type(num))
          </Paper>
          <Typography variant="p">OUTPUT :</Typography>
          <Paper className="output">&lt;class 'int'&gt;</Paper>

          <Typography variant="h6">Python Float type</Typography>
          <Typography variant="p">
            Python float is used to store floating-point numbers like 1.9,
            9.902, 15.2, etc. It is accurate up to 15 decimal places.
          </Typography>
          <Typography variant="p">
            This is a real number with a floating-point representation. It is
            specified by a decimal point. Optionally, the character e or E
            followed by a positive or negative integer may be appended to
            specify scientific notation. . Some examples of numbers that are
            represented as floats are 0.5 and -7.823457. They can be created
            directly by entering a number with a decimal point, or by using
            operations such as division on integers. Extra zeros present at the
            number’s end are ignored automatically.
          </Typography>
        </Paper>
        <Typography variant="h6" textAlign="center">
            Will discuss more about data types in perticular section
        </Typography>
      </Paper>
    </ThemeProvider>
  );
};
