import {
  createTheme,
  CssBaseline,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import { Helmet } from "react-helmet";
import { ThemeProvider } from "styled-components";
import { StatementPython } from "./StatementPython";
import { VariableAndPython } from "./VariableAndPython";

export const IntroductionPython = () => {
  
  const paperStyle = {
    p: 2,
    display: "flex",
    flexDirection: "column",
    height: "auto",
    backgroundColor: "#E6E6E6", // Set the background color to grey
    mt: 2,
  };
  const defaultTheme = createTheme();
  return (
    
    <ThemeProvider theme={defaultTheme}>
      <Helmet>
        <title>Introduction to Python</title>
        <meta name="description" content="Introduction to Python" />
        <meta name="keywords" content="Introduction to Python" />
        <meta name="author" content="Samir Vithlani" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta charset="UTF-8" />
      </Helmet>
      <CssBaseline />
      <Paper
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          height: "auto",
          backgroundColor: "#E6E6E6", // Set the background color to grey
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="p">
              This Python Tutorial is very well suited for Beginners, and also
              for experienced programmers with other programming languages like
              C++ and Java. This specially designed Python tutorial will help
              you learn Python Programming Language in the most efficient way,
              with topics from basics to advanced (like Web-scraping, Django,
              Deep-Learning, etc.) with examples.
            </Typography>
          </Grid>
        </Grid>
      </Paper>
      {/* this is for header */}
      <Grid mt={2}>
        <Typography variant="h6">What is Python?</Typography>
      </Grid>

      <Paper
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          height: "auto",
          backgroundColor: "#E6E6E6", // Set the background color to grey
          mt: 2,
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
              Python is a high-level, general-purpose, and very popular
              programming language. Python programming language (latest Python
              3) is being used in web development, Machine Learning
              applications, along with all cutting-edge technology in the
              Software Industry.
            </Typography>

            <Typography variant="body1" gutterBottom>
              Python language is being used by almost all tech-giant companies
              like – Google, Amazon, Facebook, Instagram, Dropbox, Uber… etc.
            </Typography>

            <Typography variant="body1">
              The biggest strength of Python is a huge collection of standard
              libraries which can be used for the following:
            </Typography>

            <ul>
              <li>Machine Learning</li>
              <li>GUI Applications (like Kivy, Tkinter, PyQt, etc.)</li>
              <li>
                Web frameworks like Django (used by YouTube, Instagram, Dropbox)
              </li>
              <li>Image processing (like OpenCV, Pillow)</li>
              <li>Web scraping (like Scrapy, BeautifulSoup, Selenium)</li>
              <li>Test frameworks</li>
              <li>Multimedia</li>
              <li>Scientific computing</li>
              <li>Text processing and many more...</li>
            </ul>
          </Grid>
        </Grid>
      </Paper>
      <Paper

        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          height: "auto",
          backgroundColor: "#E6E6E6", // Set the background color to grey
          mt: 2,
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6">Why Python?</Typography>
            <ul>
              <li>
                Python is an interpreted language, eliminating the need for a
                separate compilation step.
              </li>
              <li>Programs can be directly run from the source code.</li>
              <li>
                Python internally converts the source code into bytecodes, which
                are then translated into the native language of the computer for
                execution.
              </li>
              <li>
                Unlike languages like C and C++, Python users don't need to
                worry about linking, loading libraries, etc.
              </li>
              <li>
                It's platform-independent, allowing development and execution on
                various operating systems, including Linux, Windows, Macintosh,
                and Solaris.
              </li>
              <li>Python is free, open-source, and redistributable.</li>
              <li>
                As a high-level language, Python abstracts low-level details,
                such as memory management, from the programmer.
              </li>
              <li>
                It's simple and easy to learn, with syntax that resembles
                English, focusing on problem-solving rather than syntax
                intricacies.
              </li>
              <li>
                Python can be embedded within C/C++ programs, providing
                scripting capabilities.
              </li>
              <li>
                It boasts robust features, including exceptional handling and
                built-in memory management techniques.
              </li>
              <li>
                The language has a rich library support system, following the
                "batteries included" philosophy.
              </li>
              <li>
                This support covers regular expressions, documentation
                generation, unit testing, threading, databases, web browsers,
                CGI, email, XML, HTML, WAV files, cryptography, GUI, and more.
              </li>
              <li>
                Additionally, there are high-quality external libraries like the
                Python Imaging Library for image manipulation.
              </li>
            </ul>
          </Grid>
        </Grid>
        
      </Paper>
      <Paper sx ={paperStyle}>
          <StatementPython/>
      </Paper>
      <Paper sx={paperStyle}>
        <VariableAndPython/>
      </Paper>
     
    </ThemeProvider>
  );
};
