import { Paper, Typography } from "@mui/material";
import { createTheme } from "@mui/system";
import React from "react";
import { ThemeProvider } from "styled-components";

export const ListTask = () => {
  const defaultTheme = createTheme();
  const paperStyle = {
    p: 2,
    display: "flex",
    flexDirection: "column",
    height: "auto",
    backgroundColor: "#E6E6E6", // Set the background color to grey
    mt: 2,
  };
  return (
    <ThemeProvider theme={defaultTheme}>
      <Paper sx={paperStyle}>
        <Typography variant="h6">List Task :</Typography>
        <ul style={{color:"blue"}}>
          <li>
            Python program to interchange first and last elements in a list
          </li>
          <li>Python program to swap two elements in a list</li>
          <li>Python – Swap elements in String list</li>
          <li>Python | Ways to find length of list</li>
          <li>Maximum of two numbers in Python</li>
          <li>Minimum of two numbers in Python</li>
          <li>Python | Ways to check if element exists in list</li>
          <li>Different ways to clear a list in Python</li>
          <li>Python | Reversing a List</li>
          <li>Python | Cloning or Copying a list</li>
          <li>Python | Count occurrences of an element in a list</li>
          <li>Python Program to find sum and average of List in Python</li>
          <li>Python | Sum of number digits in List</li>
          <li>Python | Multiply all numbers in the list</li>
          <li>Python program to find smallest number in a list</li>
          <li>Python program to find largest number in a list</li>
          <li>Python program to find second largest number in a list</li>
          <li>Python program to print even numbers in a list</li>
          <li>Python program to print odd numbers in a List</li>
          <li>Python program to print all even numbers in a range</li>
        </ul>
      </Paper>
    </ThemeProvider>
  );
};
