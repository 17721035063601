import { createTheme, Paper, ThemeProvider, Typography } from "@mui/material";
import React from "react";

export const FunctionPython = () => {
  const defaultTheme = createTheme();
  const paperStyle = {
    p: 2,
    display: "flex",
    flexDirection: "column",
    height: "auto",
    backgroundColor: "#E6E6E6", // Set the background color to grey
    mt: 2,
  };
  return (
    <ThemeProvider theme={defaultTheme}>
      <Paper sx={paperStyle}>
        <Typography variant="h6">Functions in Python :</Typography>
        <Typography variant="p">
          a Python function is like a set of instructions that perform a
          specific task. The concept is to group together tasks that are done
          often or repeatedly, creating a function. Instead of rewriting the
          same instructions for different inputs each time, you can use the
          function by calling it. This way, you can reuse the code inside the
          function multiple times without having to repeat it.
        </Typography>
        <Typography mt={1} variant="h6">
          Types of Functions in Python:
        </Typography>
        <ul>
          <li>Built-in functions</li>
          <li>User-defined functions</li>
        </ul>
        <Typography variant="h6">Creating a Function in Python : </Typography>
        <Typography variant="p">
          We can create a user-defined function in Python, using the def
          keyword. We can add any type of functionalities and properties to it
          as we require
        </Typography>
        <Paper className="code">
          <pre>
            {`
def function_name(parameters):
    """docstring"""              
              `}
          </pre>
        </Paper>
        <Typography variant="h6">Calling a Function in Python :</Typography>
        <Paper className="code">
          <pre>
            {`def myFun():
    print("Hello") 

myFun() #calling a function

# Output: Hello
`}
          </pre>
        </Paper>
        <Typography variant="h6">
          SUB Type of User Defined Functions :
        </Typography>
        <ul>
          <li>Function with no arguments and no return type</li>
          <li>Function with arguments and no return type</li>
          <li>Function with no arguments and return type</li>
          <li>Function with arguments and return type</li>
        </ul>

        <Typography variant="h6">
          Function with no arguments and no return type :
        </Typography>
        <Paper className="code">
          <pre>
            {`def add():
    a=10
    b=20
    c=a+b
    print(c)

add() #calling a function

# Output: 30
`}
          </pre>
        </Paper>
        <Typography variant="h6">
          Function with arguments and no return type :
        </Typography>
        <Paper className="code">
          <pre>
            {`def add(a,b):
    c=a+b
    print(c)

add(10,20) #calling a function

# Output: 30
`}
          </pre>
        </Paper>
        <Typography variant="h6">
          Function with no arguments and return type :
        </Typography>
        <Paper className="code">
          <pre>
            {`def add():
    a=10
    b=20
    c=a+b
    return c

print(add()) #calling a function

# Output: 30
`}
          </pre>
        </Paper>
        <Typography variant="h6">
          Function with arguments and return type :
        </Typography>
        <Paper className="code">
          <pre>
            {`def add(a,b):
    c=a+b
    return c

print(add(10,20)) #calling a function

# Output: 30
  `}
          </pre>
        </Paper>
      </Paper>
    </ThemeProvider>
  );
};
