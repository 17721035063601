import { createTheme, Paper, ThemeProvider, Typography } from "@mui/material";
import React from "react";

export const WhileLoopPython = () => {
  const defaultTheme = createTheme();
  const paperStyle = {
    p: 2,
    display: "flex",
    flexDirection: "column",
    height: "auto",
    backgroundColor: "#E6E6E6", // Set the background color to grey
    mt: 2,
  };
  return (
    <ThemeProvider theme={defaultTheme}>
      <Paper sx={paperStyle}>
        <Typography variant="h6">While Loop in Python :</Typography>
        <ul>
          <li>
            Python While Loop is used to execute a block of statements
            repeatedly.
          </li>
          <li>
            It continues the execution until a given condition is satisfied.
          </li>
          <li>
            When the condition becomes false, the line immediately after the
            loop in the program is executed.
          </li>
        </ul>
        <Typography variant="h6">More Explnation on while loop :</Typography>
        <ul>
          <li>
            A "while" loop falls into the category of indefinite iteration.
          </li>
          <li>
            This means that the number of times the loop will execute is not
            specified explicitly in advance.
          </li>
          <li>
            In Python, statements that are indented by the same number of spaces
            after a programming construct are considered to be part of a single
            block of code.
          </li>
          <li>Python uses indentation as its method of grouping statements.</li>
          <li>
            When a "while" loop is executed, an expression (denoted as "expr")
            is first evaluated in a Boolean context.
          </li>
          <li>If the expression is true, the body of the loop is executed.</li>
          <li>
            After the execution of the loop body, the expression is checked
            again.
          </li>
          <li>
            If it is still true, the body is executed once more, and this
            process continues until the expression becomes false.
          </li>
        </ul>
        <Typography variant="h6">Syntax of while loop :</Typography>
        <Paper className="code">
          <pre>
            {`
# Python program to illustrate
# while loop
count = 0
while (count < 3):
	count = count + 1 #count +=1
	print("Hello")

output:
Hello 
Hello 
Hello 
`}
          </pre>
        </Paper>
        <Typography variant="h6">Single statement while block :</Typography>
        <Paper className="code">
          <pre>
            {`
# Python program to illustrate 
# Single statement while block 
count = 0
while (count < 5): count += 1; print("Hello")

                    `}
          </pre>
        </Paper>
        <Typography variant="h6">Break Statement :</Typography>
        <Paper className="code">
          <pre>
            {`
# Python program to illustrate
no=1
while no<=10:
    print(no)
    no+=1
    if no==5:
        break
    `}
          </pre>
        </Paper>
        <Typography variant="h6">While loop with else :</Typography>
        <Typography variant="p">
          <ul>
            <li>
              As discussed above, while loop executes the block until a
              condition is satisfied.
            </li>
            <li>
              When the condition becomes false, the statement immediately after
              the loop is executed.
            </li>
            <li>
              The else clause is only executed when your while condition becomes
              false.
            </li>
            <li>
              If you break out of the loop, or if an exception is raised, it
              won’t be executed.
            </li>
            <li>
              Note: The else block just after for/while is executed only when
              the loop is NOT terminated by a break statement.
            </li>
          </ul>
        </Typography>
        <Paper className="code">
          <pre>
            {`
# Python program to demonstrate
# while-else loop

i = 0
while i < 4:
	i += 1
	print(i)
else: # Executed because no break in for
	print("No Break\n")

i = 0
while i < 4:
	i += 1
	print(i)
	break
else: # Not executed as there is a break
	print("No Break")


Output:
1
2
3
4
No Break

1
                    `}
          </pre>
        </Paper>
      </Paper>
    </ThemeProvider>
  );
};
