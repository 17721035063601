import { createTheme, Paper, ThemeProvider, Typography } from "@mui/material";

import React from "react";

export const FunctionPythonArgsKwargs = () => {
  const defaultTheme = createTheme();
  const paperStyle = {
    p: 2,
    display: "flex",
    flexDirection: "column",
    height: "auto",
    backgroundColor: "#E6E6E6", // Set the background color to grey
    mt: 2,
  };
  return (
    <ThemeProvider theme={defaultTheme}>
      <Paper sx={paperStyle}>
        <Typography variant="h6">*args and **kwargs in Python: </Typography>
        <Typography variant="p">
          In this article, we will cover what ** (double star/asterisk) and *
          (star/asterisk) do for parameters in Python, Here, we will also cover
          args and kwargs examples in Python. We can pass a variable number of
          arguments to a function using special symbols.
        </Typography>
        <Typography variant="h6">
          Special Symbols Used for passing arguments in Python:
        </Typography>
        <ul>
          <li>*args (Non-Keyword Arguments)</li>
          <li>**kwargs (Keyword Arguments)</li>
        </ul>
        <Typography variant="h6">*args (Non-Keyword Arguments):</Typography>
        <ul>
          <li>
            <p>
              The special syntax <code>*args</code> in function definitions in
              Python is used to pass a variable number of arguments to a
              function. It is used to pass a non-keyworded, variable-length
              argument list.
            </p>
          </li>
          <li>
            <p>
              The syntax is to use the symbol <code>*</code> to take in a
              variable number of arguments; by convention, it is often used with
              the word <code>args</code>.
            </p>
          </li>
          <li>
            <p>
              What <code>*args</code> allows you to do is take in more arguments
              than the number of formal arguments that you previously defined.
              With <code>*args</code>, any number of extra arguments can be
              tacked on to your current formal parameters (including zero extra
              arguments).
            </p>
          </li>
          <li>
            <p>
              For example, we want to make a multiply function that takes any
              number of arguments and is able to multiply them all together. It
              can be done using <code>*args</code>.
            </p>
          </li>
          <li>
            <p>
              Using the <code>*</code>, the variable that we associate with the{" "}
              <code>*</code> becomes iterable meaning you can do things like
              iterate over it, run some higher-order functions such as{" "}
              <code>map</code> and <code>filter</code>, etc.
            </p>
          </li>
        </ul>
        <Paper className="code">
          <pre>
            {`# Python program to illustrate
# *args for variable number of arguments
def myFun(*argv):
    print("type of argv = ",type(argv)
    for arg in argv:
        print (arg)

myFun('Hello', 'Welcome', 'to', 'python') #calling a function

# Output:
# type of argv =  <class 'tuple'>
# Hello
# Welcome
# to
# python

`}
          </pre>
        </Paper>
        <Typography variant="h6">
          Python program to illustrate *args with a first extra argument:
        </Typography>
        <Paper className="code">
          <pre>
            {`
def myFun(arg1, *argv):
print("First argument :", arg1)
for arg in argv:
print("Next argument through *argv :", arg)
                 
                 
myFun('Hello', 'Welcome', 'to', 'Python')                    

# Output:
# First argument : Hello
# Next argument through *argv : Welcome
# Next argument through *argv : to
# Next argument through *argv : Python


                    `}
          </pre>
        </Paper>
        <Typography variant="h6">What is Python **kwargs?</Typography>
        <ul>
          <li>
            The special syntax <code>**kwargs</code> in function definitions in
            Python is used to pass a keyworded, variable-length argument list.
          </li>
          <li>
            We use the name <code>kwargs</code> with the double star. The reason
            is that the double star allows us to pass through keyword arguments
            (and any number of them).
          </li>
          <li>
            A keyword argument is where you provide a name to the variable as
            you pass it into the function.
          </li>
          <li>
            One can think of the <code>kwargs</code> as being a dictionary that
            maps each keyword to the value that we pass alongside it.
          </li>
          <li>
            That is why when we iterate over the <code>kwargs</code> there
            doesn’t seem to be any order in which they were printed out.
          </li>
        </ul>
        <Paper className="code">
          <pre>
            {`# Python program to illustrate
# *kargs for variable number of keyword arguments

def myFun(**kwargs):
    print("type of kwargs = ",type(kwargs))
    for key, value in kwargs.items():
        print ("%s == %s" %(key, value))


myFun(first ='Hello', mid ='Welcome', last='to Python') #calling a function

# Output:
# type of kwargs =  <class 'dict'>
# first == Hello
# mid == Welcome
# last == to Python
`}
          </pre>
        </Paper>
        <Typography variant="h6">
          Using both *args and **kwargs in Python to call a function :
        </Typography>
        <ul>
          <li>
            Here, we are passing <code>*args</code> and <code>**kwargs</code> as
            arguments in the <code>myFun</code> function.
          </li>
          <li>
            Passing <code>*args</code> to <code>myFun</code> simply means that
            we pass the positional and variable-length arguments which are
            contained by <code>args</code>. So, “Geeks” is passed to{" "}
            <code>arg1</code>, “for” is passed to <code>arg2</code>, and “Geeks”
            is passed to <code>arg3</code>.
          </li>
          <li>
            When we pass <code>**kwargs</code> as an argument to{" "}
            <code>myFun</code>, it means that it accepts keyword arguments.
            Here, “arg1” is the key, and the value is “Geeks” which is passed to{" "}
            <code>arg1</code>. Similarly, “for” and “Geeks” are passed to{" "}
            <code>arg2</code> and <code>arg3</code> respectively.
          </li>
          <li>
            After passing all the data, we are printing all the data in lines.
          </li>
        </ul>
        <Paper className="code">
          <pre>
            {`
def myFun(*args, **kwargs):
    print("type of args = ",type(args))
    print("type of kwargs = ",type(kwargs))
    print("args = ",args)
    print("kwargs = ",kwargs)


myFun('Geeks', 'for', 'Geeks', first="Geeks", mid="for", last="Geeks") #calling a function

# Output:
# type of args =  <class 'tuple'>
# type of kwargs =  <class 'dict'>
# args =  ('Geeks', 'for', 'Geeks')
# kwargs =  {'first': 'Geeks', 'mid': 'for', 'last': 'Geeks'}  
    `}
          </pre>
        </Paper>
      </Paper>
    </ThemeProvider>
  );
};
