import { Paper, Typography } from "@mui/material";
import React from "react";
import "./Code.css";

export const VariableAndPython = () => {
  const paperStyle = {
    p: 2,
    display: "flex",
    flexDirection: "column",
    height: "auto",
    backgroundColor: "#E6E6E6", // Set the background color to grey
    mt: 2,
  };
  return (
    <>
      <ul>
        <li>
          <Typography variant="h6">Example of Variable in Python</Typography>
          <ul>
            <li>
              <Typography>
                Python Variables are containers that store values.
              </Typography>
            </li>
            <li>
              <Typography>
                Python is not "statically typed". We do not need to declare
                variables before using them or declare their type.
              </Typography>
            </li>
            <li>
              <Typography>
                A variable is created the moment we first assign a value to it.
                A Python variable is a name given to a memory location. It is
                the basic unit of storage in a program.
              </Typography>
            </li>
          </ul>
        </li>
      </ul>
      <ul>
        <li>
          <Typography variant="h6">Example of a Variable in Python</Typography>
          <ul>
            <li>
              A variable in Python is a representational name that acts as a
              pointer to an object.
            </li>
            <li>
              Once an object is assigned to a variable, it can be referred to by
              that name.
            </li>
            <li>
              In simple terms, variables in Python are containers that store
              values.
            </li>
            <li>
              We've stored the value "hello python" in a variable, and when we
              use the variable's name, the stored information will be printed.
            </li>
          </ul>
        </li>
      </ul>
      <Paper className="code">
        <Typography variant="p">
          Var = "Geeksforgeeks"
          <br />
          print(Var)
        </Typography>
      </Paper>
      <ul>
        <li>
          <Typography variant="h6">Rules for Python Variables</Typography>
          <ul>
            <li>
              A Python variable name must start with a letter or the underscore
              character.
            </li>
            <li>A Python variable name cannot start with a number.</li>
            <li>
              A Python variable name can only contain alpha-numeric characters
              and underscores (A-z, 0-9, and _).
            </li>
            <li>
              Variable names in Python are case-sensitive (name, Name, and NAME
              are different).
            </li>
            <li>
              The reserved words (keywords) in Python cannot be used to name
              variables.
            </li>
          </ul>
        </li>
      </ul>
      <Paper className="code">
        <Typography variant="p">
          #valid variable Name
          <br />
          username = "John"
          <br />
          Username = "John"
          <br />
          user_name = "John"
          <br />
          _userName = "John"
          <br />
          userName_ = "John"
          <br />
          _userName_ = 8<br />
        </Typography>
      </Paper>
      <Typography variant="h6">
        Check DataType of Variable in Python
        <br />
        <Typography variant="body1">
          <ul>
            <li>
              To check the data type of a variable, we can use the type()
              function.
            </li>
            <li>
              The type() function returns the class name of the argument it
              receives.
            </li>
          </ul>
        </Typography>
        Example :
        <Paper className="code">
          name = "John"
          <br />
          age = 23
          <br />
          pi = 3.14
          <br />
          print("type of name = ",type(name))
          <br />
          print("type of age = ",type(age))
          <br />
          print("type of pi = ",type(pi))
          <br />
        </Paper>
        <Typography variant="h6">OUTPUT:</Typography>
        <Paper className="output">
          <Typography variant="p">
            type of name = &lt;class 'str'&gt;
          </Typography>
          <br />
          <Typography variant="p">type of age = &lt;class 'int'&gt;</Typography>
          <br />
          <Typography variant="p">
            type of pi = &lt;class 'float'&gt;
          </Typography>
        </Paper>
        <Typography variant="h6">Assigning Multiple Values</Typography>
        <Typography variant="p">
          Also, Python allows assigning a single value to several variables
          simultaneously with “=” operators.
        </Typography>
        <Paper className="code">
          a = b = c = 10
          <br />
          print(a)
          <br />
          print(b)
          <br />
          print(c)
          <br />
        </Paper>
      </Typography>
      <Typography variant="h6">OUTPUT:</Typography>
      <Paper className="output">
        10
        <br />
        10
        <br />
        10
        <br />
      </Paper>
      <Typography variant="h6">
        Assigning different values to multiple variables
      </Typography>
      <Typography variant="p">
        Python allows adding different values in a single line with “,”
        operators.
      </Typography>
      <Paper className="code">a, b, c = 10, 20, 30</Paper>
      
    </>
  );
};
