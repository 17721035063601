import React from "react";
import {
  Box,
  createTheme,
  Grid,
  Paper,
  Typography,
  Link,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { ThemeProvider } from "styled-components";
import Header from "../../Layouts/Header";
import { Link as RouterLink } from "react-router-dom";
import pythonImage from "../../assets/images/pythonbox.png";
import javaImage from "../../assets/images/javabox.png";
import cImage from "../../assets/images/cbox.png";
import jsImage from "../../assets/images/jsbox.png";
import reactImage from "../../assets/images/reactbox.png";
import mongoImage from "../../assets/images/mongobox.png";
import djangoImage from "../../assets/images/djangobox.png";
import angularImage from "../../assets/images/angularbox.png";
import springImage from "../../assets/images/springbox.png";
import gtuFinalYear from "../../assets/images/gtuproj.png";

export const MainDashBoard = () => {
  const defaultTheme = createTheme();
  const paperStyle = {
    p: 1,
    display: "flex",
    flexDirection: "column",
    height: "auto",
    backgroundColor: "white",
    mt: 1,
  };
  const boxStyles = {
    width: 151,
    height: 131,
    margin: "8px",
    boxShadow: "0px 8px 16px rgba(0.3, 0.3, 0.3, 0.3)",
  };

  const programmingLanguage = [
    { image: pythonImage, content: "Python", link: "/python" },
    { image: javaImage, content: "Java", link: "/java" },
    { image: cImage, content: "C language", link: "/c" },
    { image: jsImage, content: "Java Script", link: "/js" },

    // Add more boxes as needed
  ];
  const webTechnologies = [
    { image: springImage, content: "Spring", link: "/spring" },
    { image: reactImage, content: "React Js", link: "/react" },
    { image: djangoImage, content: "Django", link: "/django" },
    { image: angularImage, content: "Angular", link: "/angular" },
  ];
  const dataBases = [
    { image: mongoImage, content: "Mongo DB", link: "/mongo" },
  ];
  const pythonLibraries = [];
  const intetviewSection = [];
  const data = [
    {
      heading: "GTU Final sem Project",
      image: gtuFinalYear,
      content: ["Item 1", "Item 2", "Item 3"],
    },
    {
      heading: "GTU Mid sem Project",
      image: gtuFinalYear,
      content: ["Item A", "Item B", "Item C"],
    },
  ];
  const boxStyles1 = {
    width: "350px",
    height: "250px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Add shadow
    margin: "16px", // Add margin between boxes
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "16px",
    borderRadius: "8px", // Add border radius for rounded corners
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Paper sx={paperStyle}>
        <Header />
        <Box overflow="auto">
          <Grid mt={1} container spacing={3}>
            <Grid item xs={7}>
            <Typography variant="h5">New Launches</Typography>
              <Grid container spacing={2}>
                {data.map((boxData, index) => (
                  <Grid item key={index}>
                    <Box sx={boxStyles1}>
                      <img
                        src={boxData.image}
                        alt={`Image ${index + 1}`}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </Box>
                  </Grid>
                ))}
              </Grid>

              <Grid>
                <Typography variant="h5" mb={1}>
                  Programming languages:
                </Typography>
                <Grid container spacing={1}>
                  {programmingLanguage.map((box, index) => (
                    <Grid item key={index}>
                      <Link
                        component={RouterLink}
                        to={box.link}
                        underline="none"
                        textAlign="center"
                        style={{ textDecoration: "none", color: "green" }}
                      >
                        <Box
                          sx={{
                            ...boxStyles,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            padding: "8px",
                          }}
                        >
                          <img
                            src={box.image}
                            alt={box.content}
                            style={{
                              width: "70%",
                              height: "70%",
                              objectFit: "cover",
                              marginTop: "15px", // Add margin from the top
                              marginRight: "8px", // Add margin from the right
                              marginBottom: "8px", // Add margin from the bottom
                              marginLeft: "20px", // Add margin from the left
                            }}
                          />
                          <Typography
                            variant="body2"
                            sx={{ textDecoration: "none" }}
                          >
                            {box.content}
                          </Typography>
                        </Box>
                      </Link>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid>
                <Typography variant="h5" mt={1}>
                  Web Technologies:
                </Typography>
                <Grid container spacing={1}>
                  {webTechnologies.map((box, index) => (
                    <Grid item key={index}>
                      <Link
                        component={RouterLink}
                        to={box.link}
                        underline="none"
                        textAlign="center"
                        style={{ textDecoration: "none", color: "green" }}
                      >
                        <Box
                          sx={{
                            ...boxStyles,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            padding: "8px",
                          }}
                        >
                          <img
                            src={box.image}
                            alt={box.content}
                            style={{
                              width: "70%",
                              height: "70%",
                              objectFit: "cover",
                              marginTop: "15px", // Add margin from the top
                              marginRight: "8px", // Add margin from the right
                              marginBottom: "8px", // Add margin from the bottom
                              marginLeft: "20px", // Add margin from the left
                            }}
                          />
                          <Typography
                            variant="body2"
                            sx={{ textDecoration: "none" }}
                          >
                            {box.content}
                          </Typography>
                        </Box>
                      </Link>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              {/* <Grid>
                <Typography variant="h5">Data Bases:</Typography>
                <Grid container spacing={1}>
                  {dataBases.map((box, index) => (
                    <Grid item key={index}>
                      <Link
                        component={RouterLink}
                        to={box.link}
                        underline="none"
                        textAlign="center"
                        style={{ textDecoration: "none", color: "green" }}
                      >
                        <Box
                          sx={{
                            ...boxStyles,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            padding: "8px",
                          }}
                        >
                          <img
                            src={box.image}
                            alt={box.content}
                            style={{
                              width: "70%",
                              height: "70%",
                              objectFit: "cover",
                              marginTop: "15px", // Add margin from the top
                              marginRight: "8px", // Add margin from the right
                              marginBottom: "8px", // Add margin from the bottom
                              marginLeft: "20px", // Add margin from the left
                            }}
                          />
                          <Typography
                            variant="body2"
                            sx={{ textDecoration: "none" }}
                          >
                            {box.content}
                          </Typography>
                        </Box>
                      </Link>
                    </Grid>
                  ))}
                </Grid>
              </Grid> */}
            </Grid>
            <Grid item xs={5} style={{ backgroundColor: "#F5F5F5" }}>
              <Typography variant="h5">Announcements</Typography>
              <ul style={{ color: "green" }}>
                <li>Hello</li>
              </ul>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </ThemeProvider>
  );
};
