import React, { useEffect, useState } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PeopleIcon from "@mui/icons-material/People";
import BarChartIcon from "@mui/icons-material/BarChart";
import LayersIcon from "@mui/icons-material/Layers";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { Collapse, Divider, List, ListItem } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import TaskIcon from "@mui/icons-material/Task";
import ListIcon from "@mui/icons-material/List";

export const UrlList = () => {
  const [currentUrl, setcurrentUrl] = useState("");
  const [urlList, seturlList] = useState()
  useEffect(() => {
    const geturl = window.location.pathname.split("/")[1];
    setcurrentUrl(geturl);

    
      currentUrl === "python" ||
      currentUrl === "python/" ||
      currentUrl === "Python" ||
      currentUrl === "Python/"  ? seturlList(pythonListItemsData) : seturlList(javaListItemsData)
      console.log("urlList",urlList)
      
  
  }, [currentUrl]);

  console.log("geturl", currentUrl);
  const pythonListItemsData = [
    {
      text: "Introduction",
      icon: <DashboardIcon />,
      link: "introduction",
      style: { textDecoration: "none", color: "black" },
    },
    {
      text: "datatype",
      icon: <PeopleIcon />,
      link: "datatype",
      style: { textDecoration: "none", color: "black" },
    },
    {
      text: "Input-output",
      icon: <ShoppingCartIcon />,
      link: "inputoutput",
      style: { textDecoration: "none", color: "black" },
    },

    {
      text: "Operators",
      icon: <BarChartIcon />,
      link: "operators",
      style: { textDecoration: "none", color: "black" },
    },
    {
      text: "Control Flow",
      icon: <LayersIcon />,
      link: "controlflow",
      style: { textDecoration: "none", color: "black" },
      subitems: [
        {
          text: "If Statement",
          link: "controlflow/ifstatement",
          icon: <TaskIcon />,
        },
        {
          text: "For Loop",
          link: "controlflow/forloop",
          icon: <TaskIcon />,
        },
        {
          text: "While Loop",
          link: "controlflow/whileloop",
          icon: <TaskIcon />,
        },
        {
          text: "Task",
          link: "controlflow/task",
          icon: <TaskIcon />,
        },
      ],
    },
    {
      text: "String",
      icon: <LayersIcon />,
      link: "strings",
      style: { textDecoration: "none", color: "black" },
      subitems: [
        { text: "String Task", link: "strings/task", icon: <TaskIcon /> },
      ],
    },
    {
      text: "Function",
      icon: <LayersIcon />,
      link: "function",
      style: { textDecoration: "none", color: "black" },
      subitems: [
        {
          text: "*args and **kwargs",
          link: "function/argsandkwargs",
          icon: <TaskIcon />,
        },
        {
          text: "Lambda Function",
          link: "function/lambdafunction",
          icon: <TaskIcon />,
        },
        {
          text: "Inner Function",
          link: "function/nestedfunction",
          icon: <TaskIcon />,
        },
      ],
    },
    {
      text: "List",
      icon: <ListIcon />,
      link: "list",
      style: { textDecoration: "none", color: "black" },
      subitems: [
        {
          text: "List Slicing",
          link: "list/slicing",
          icon: <TaskIcon />,
        },
        {
          text: "List Comprehension",
          link: "list/comprehension",
          icon: <TaskIcon />,
        },
        {
          text: "List Task",
          link: "list/task",
          icon: <TaskIcon />,
        },
      ],
    },
    {
      text: "Tuple",
      icon: <ListIcon />,
      link: "tuple",
      style: { textDecoration: "none", color: "black" },
    },
    {
      text: "Dictionary",
      icon: <ListIcon />,
      link: "dictionary",
      style: { textDecoration: "none", color: "black" },
    },
    {
      text: "Set",
      icon: <ListIcon />,
      link: "set",
      style: { textDecoration: "none", color: "black" },
    },
    {
      text: "OOPS",
      icon: <ListIcon />,
      link: "oopspython",
      style: { textDecoration: "none", color: "black" },
    },
    {
      text: "Exception Handling",
      icon: <ListIcon />,
      link: "exceptionhandling",
      style: { textDecoration: "none", color: "black" },
    },
    {
      text: "File Handling",
      icon: <ListIcon />,
      link: "filehandling",
      style: { textDecoration: "none", color: "black" },
    },
  ];

  const javaListItemsData = []

  const [openItem, setOpenItem] = useState(null);

  const handleToggle = (index) => {
    setOpenItem((prevOpenItem) => (prevOpenItem === index ? null : index));
  };

  
    return (
      <React.Fragment>
        {urlList?.map((res, index) => (
          <List key={index}>
            {/* <Divider sx={{ my: 1 }} /> */}
            <ListItem
              disablePadding
              component={RouterLink}
              to={res.link}
              style={{ marginTop: "10px" }}
            >
              <ListItemButton onClick={() => handleToggle(index)}>
                <ListItemIcon>{res.icon}</ListItemIcon>
                <ListItemText primary={res.text} />
                {openItem === index ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItemButton>
            </ListItem>
            <Collapse in={openItem === index} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {res.subitems &&
                  res.subitems.map((subitem, subIndex) => (
                    <ListItem
                      key={subIndex}
                      disablePadding
                      component={RouterLink}
                      to={subitem.link}
                      style={{ marginTop: "10px" }}
                    >
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>{subitem.icon}</ListItemIcon>
                        <ListItemText primary={subitem.text} />
                      </ListItemButton>
                    </ListItem>
                  ))}
              </List>
            </Collapse>
          </List>
        ))}
      </React.Fragment>
    );
  
};
