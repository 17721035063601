import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./Code.css";

export const StatementPython = () => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <>
      <Typography variant="h6">
        Statement, Indentation and Comment in Python
      </Typography>
      <ul>
        <li>
          <Typography variant="p">
            "We're going to talk about statements, indentation, and comments in
            Python. We'll cover the rules and examples for Python statements,
            Python indentation, Python comments, and understand the difference
            between 'docstrings' and 'multi-line comments'."
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            Statement in Python can be extended to one or more lines using
            parentheses (), braces {"{}"}, square brackets {"[]"}, semi-colon
            (;), and continuation character slash {"\\"}.
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            When the programmer needs to do long calculations and cannot fit his
            statements into one line, one can make use of these characters.
          </Typography>
        </li>
      </ul>
      <div>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{ width: "33%", flexShrink: 0 }}>
              Declared using Continuation Character (\):
            </Typography>
            {/* <Typography sx={{ color: 'text.secondary' }}>I am an accordion</Typography> */}
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <Typography>
                s = 1 + 2 + 3 + \ <br />
                4 + 5 + 6 + \ <br />7 + 8 + 9
              </Typography>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={{ width: "33%", flexShrink: 0 }}>
              Declared using parentheses () :
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>n = (1 * 2 * 3 + 7 + 8 + 9)</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography sx={{ width: "33%", flexShrink: 0 }}>
              Declared using square brackets [] :
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ color: "text.secondary" }}>
              crickters = ['Dhoni', <br />
              'Kohli',
              <br />
              'Rohit']
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            <Typography sx={{ width: "33%", flexShrink: 0 }}>
              Declared using braces {} :
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              x ={" "}
              <p>
                {" "}
                1 + 2 + 3 + 4 + 5 + 6 + <br />7 + 8 + 9`
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
      <Grid mt={2}>
        <Typography variant="h6">Python Indentation :</Typography>
        <Typography variant="body1">
          Whitespace, or the spaces and tabs at the beginning of lines, plays a
          crucial role in Python for indentation. Unlike some other programming
          languages where indentation is merely for readability, in Python, it's
          obligatory. To grasp this concept better, let's explore an example of
          indentation in Python.
        </Typography>
        <Typography variant="h6">Role of Indentation in Python</Typography>
        <Typography variant="body1">
          <ul>
            <li>
              A block is a combination of statements that serve a specific
              purpose.
            </li>
            <li>It can be considered as the grouping of statements.</li>
            <li>
              In many programming languages like C, C++, and Java, braces are
              used to define a block of code through indentation.
            </li>
            <li>
              Python, uniquely, uses indentation to signify blocks of code.
            </li>
            <li>
              All statements with the same indentation belong to the same block
              of code.
            </li>
            <li>
              If a block needs to be more deeply nested, it is indented further
              to the right.
            </li>
          </ul>
        </Typography>
      </Grid>
      <Grid mt={2}>
        <Typography variant="h6">Python Comments :</Typography>
        <ul>
          <li>
            Python comments start with the hash symbol (#) and extend to the end
            of the line.
          </li>
          <li>
            They are useful annotations that developers provide to help readers
            understand the source code.
          </li>
          <li>Comments explain the logic or specific parts of the code.</li>
          <li>
            They are particularly helpful for those maintaining or enhancing the
            code in the absence of the original author.
          </li>
          <li>
            Comments don't impact the program's output but greatly enhance the
            code's overall readability.
          </li>
        </ul>
      </Grid>
      <Grid mt={2}>
        <Typography variant="p">
          Types of comments in Python A comment can be written on a single line,
          next to the corresponding line of code, or in a block of multiple
          lines. Here, we will try to understand examples of comment in Python
          one by one:
        </Typography>
        <Typography marginTop={2} variant="h6">
          Single Line Comment
        </Typography>
        <Typography variant="body1">
          <ul>
            <li>
              Python single-line comments start with a hash symbol (#) with no
              white spaces and extend to the end of the line.
            </li>
            <li>
              If a comment exceeds one line, a hashtag is placed on the next
              line to continue the comment.
            </li>
            <li>
              Python's single-line comments are valuable for providing brief
              explanations for variables, function declarations, and
              expressions.
            </li>
          </ul>
          <Paper className="code">
            <Grid padding={2}>
              # This is a comment
              <br />
              # Print “GeeksforGeeks” to console
              <br />
              print("GeeksforGeeks")
            </Grid>
          </Paper>
        </Typography>
        <Typography marginTop={2} variant="h6">
          Multiline comment in Python
        </Typography>
        <Typography variant="body1">
          <ul>
            <li>
              Python doesn't support multiline comments using a hash symbol (#)
              for each extra line.
            </li>
            <li>
              Multiline comments can be achieved with multiline strings,
              enclosed in triple double-quotes (`"""`).
            </li>
            <li>
              No white space should be present between the triple double-quotes.
            </li>
            <li>
              These comments are helpful when the text doesn't fit into one line
              and needs to span across lines.
            </li>
            <li>
              They serve as documentation, providing additional information for
              others reading your code.
            </li>
          </ul>
        </Typography>
        <p>EXAMPLE :</p>
        In this example, we are using three double quotes (“) at the start and
        end of the string without any space to create a Python multiline
        comment.
        <Paper className="code">
          <Typography>
            ' ' ' This would be a multiline comment in Python that <br />
            spans several lines and describes geeksforgeeks.
            <br />
            A Computer Science portal for geeks. It contains <br />
            well written, well thought <br />
            and well-explained computer science
            <br />
            and programming articles, <br />
            quizzes and more.
            <br />' ' '
          </Typography>
        </Paper>
        
      </Grid>
    </>
  );
};
