import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Link as RouterLink,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SkidLogo from "../assets/images/skidlogo.png"; // Replace with the correct path to your logo
import pythonImage from "../assets/images/python.png";
import javaImage from "../assets/images/java.png";
import cImage from "../assets/images/c.png";
import jsImage from "../assets/images/js.png";
import interviewImage from "../assets/images/interviewimage.png";
import { Link } from "react-router-dom";

const Header = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  useEffect(() => {
    const updateWindowSize = () => {
      setIsMobile(window.innerWidth < 960); // Adjust the breakpoint as needed
    };
    window.addEventListener("resize", updateWindowSize);

    // Initial check
    updateWindowSize();

    // Cleanup
    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  const pages = [
    {
      name: "Python",
      link: "/python",
      imageUrl: pythonImage,
    },
    {
      name: "Java",
      link: "/java",
      imageUrl: javaImage,
    },
    {
      name: "C language",
      link: "/c",
      imageUrl: cImage,
    },
    {
      name: "Java Script",
      link: "/js",
      imageUrl: jsImage,
    },
    {
      name: "Interview Section",
      link: "/interview",
      imageUrl: interviewImage,
    }
  ];

  return (
    <div>
      {isMobile && (
        <div style={{ textAlign: "center", marginBottom: "10px" }}>
          <img
            src={SkidLogo}
            alt="Skid Logo"
            style={{ height: "60px", width: "250px" }}
          />
          
        </div>
      )}
      <AppBar position="static" sx={{ backgroundColor: "#115f64" }}>
        <Toolbar>
          {isMobile && (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerOpen}
            >
              <MenuIcon /> Menu
            </IconButton>
          )}
          {!isMobile && (
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              <img
                src={SkidLogo}
                alt="Skid Logo"
                style={{ height: "40px", marginRight: "8px" }}
              />
            </Typography>
          )}
          {!isMobile && (
            <List sx={{ display: "flex", gap: 1 }}>
              {pages.map((page) => (
                <ListItem
                  key={page.name}
                  component={Link}
                  to={page.link}
                  sx={{ textDecoration: "none", color: "white" }}
                >
                  <ListItemAvatar>
                    <Avatar alt={page.name} src={page.imageUrl} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={page.name}
                    sx={{ fontSize: "0.8rem", fontFamily: "Lora" }}
                  />
                </ListItem>
              ))}
            </List>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="left"
        open={isDrawerOpen}
        onClose={handleDrawerClose}
        sx={{ width: "240px" }}
      >
        <List>
          {pages.map((page) => (
            <ListItem
              key={page.name}
              component={Link}
              to={page.link}
              sx={{ textDecoration: "none" }}
            >
              <ListItemAvatar>
                <Avatar alt={page.name} src={page.imageUrl} />
              </ListItemAvatar>
              <ListItemText
                primary={page.name}
                sx={{ fontSize: "0.8rem", fontFamily: "sans-serif" }}
              />
            </ListItem>
          ))}
        </List>
      </Drawer>
    </div>
  );
};

export default Header;
