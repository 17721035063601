import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
// import { HashRouter as BrowserRouter } from "react-router-dom";

import SideBar from "../../Layouts/SideBar";
import { Login } from "../Login/Login";
import { Orders } from "../Orders";
import { DataTypesPython } from "../Python/DataTypesPython";
import { InputOutput } from "../Python/InputOutput";
import { IntroductionPython } from "../Python/IntroductionPython";
import { Test } from "../Test";
import { Operators } from "../Python/Operators";
import { StringPython } from "../Python/StringPython";
import { StringTask } from "../Python/StringTask";
import { ControlFlowPython } from "../Python/ControlFlowPython";
import { IfStatementPython } from "../Python/IfStatementPython";
import { ForLoopPython } from "../Python/ForLoopPython";
import { WhileLoopPython } from "../Python/WhileLoopPython";
import { ControlFlowTask } from "../Python/ControlFlowTask";
import { ListPython } from "../Python/ListPython";
import { SlicingList } from "../Python/SlicingList";
import { ListComprehensionPython } from "../Python/ListComprehensionPython";
import { ListTask } from "../Python/ListTask";
import { TuplePython } from "../Python/TuplePython";
import JavaSideBar from "../../Layouts/JavaSideBar";
import { MainDashBoard } from "../MainDashBoard/MainDashBoard";
import { Error404Page } from "../Error404Page";
import Header from "../../Layouts/Header";
import { DictonryPython } from "../Python/DictonryPython";
import { SetPython } from "../Python/SetPython";
import { OOPPython } from "../Python/OOPPython";
import { ExceptionHandlePython } from "../Python/ExceptionHandlePython";
import { FileHandlingPython } from "../Python/FileHandlingPython";
import { FunctionPython } from "../Python/FunctionPython";
import { FunctionPythonArgsKwargs } from "../Python/FunctionPythonArgsKwargs";
import { FunctionPythonLambda } from "../Python/FunctionPythonLambda";
import { FunctionPythonNested } from "../Python/FunctionPythonNested";

const MainRouter = ({ children }) => {
  //console.log("MainRouter",children);
  
  const routesData = createBrowserRouter([
    {
      path: "/",
      element: <MainDashBoard/>,
      errorElement: <Error404Page/>,
    },
    {
      path: "/login",
      element: <Login />,
      errorElement: <Error404Page/>,
    },
    {
      path: "/python",
      element: <SideBar />,
      errorElement: <Error404Page/>,

      children: [
        {
          path: "",
          element: <IntroductionPython />,
        }
        ,
        {
          path: "introduction",
          element: <IntroductionPython />,
          errorElement: <Error404Page/>,
        },
        {
          path: "inputoutput",
          element: <InputOutput />,
          errorElement: <Error404Page/>,
        },
        {
          path: "operators",
          element: <Operators />,
          errorElement: <Error404Page/>,
        },
        {
          path: "datatype",
          element: <DataTypesPython />,
          errorElement: <Error404Page/>,
        },
        {
          path:"controlflow",
          element:<ControlFlowPython/>,
          errorElement:<div>404</div>
        },
        {
          path:"controlflow/ifstatement",
          element:<IfStatementPython/>,
          errorElement:<div>404</div>
        },{
          path:"controlflow/forloop",
          element:<ForLoopPython/>,
          errorElement:<div>404</div>
        },
        {
          path:"controlflow/whileloop",
          element:<WhileLoopPython/>,
          errorElement:<div>404</div>
        },
        {
          path:"controlflow/task",
          element:<ControlFlowTask/>,
          errorElement:<div>404</div>
        },
        {
          path: "strings",
          element: <StringPython />,
          errorElement: <Error404Page/>,
        },
        {
          path:"strings/task",
          element:<StringTask/>,
          errorElement:<div>404</div>
        },
        {
          path: "list",
          element: <ListPython/>,
          errorElement: <Error404Page/>,
        },
        {
          path:"list/slicing",
          element:<SlicingList/>,
          errorElement:<div>404</div>
        },
        {
          path: "list/comprehension",
          element: <ListComprehensionPython/>,
          errorElement: <Error404Page/>,
        },
        {
          path: "list/task",
          element: <ListTask/>,
          errorElement: <Error404Page/>,
        },
        {
          path: "tuple",
          element: <TuplePython/>,
          errorElement: <Error404Page/>,

        },
        {
          path: "dictionary",
          element: <DictonryPython/>,
          errorElement: <Error404Page/>,
        },
        {
          path: "set",
          element: <SetPython/>,
          errorElement: <Error404Page/>,
        },
        {
          path: "oopspython",
          element: <OOPPython/>,
          errorElement: <Error404Page/>,
        },
        {
          path:"exceptionhandling",
          element:<ExceptionHandlePython/>,
          errorElement:<Error404Page/>
        },
        {
          path: "filehandling",
          element: <FileHandlingPython/>,
          errorElement: <Error404Page/>,
        },
        {
          path: "function",
          element: <FunctionPython/>,
          errorElement: <Error404Page/>,
        },
        {
          path:"function/argsandkwargs",
          element:<FunctionPythonArgsKwargs/>,
          errorElement:<Error404Page/>
        },
        {
          path: "function/lambdafunction",
          element: <FunctionPythonLambda/>,
          errorElement: <Error404Page/>,
        },
        {
          path: "function/nestedfunction",
          element: <FunctionPythonNested/>,
          errorElement: <Error404Page/>,
        }
      ],
      
    },
    {
      path: "/java",
      element: <JavaSideBar/>,
      errorElement: <Error404Page/>,
    },
    {
      basepath:"/",
    }
  ]);
  console.log("MainRouter", routesData);
  console.log("MainRouter", routesData.routes);
  return (
    <React.Fragment>
      
      <RouterProvider router={routesData}>{children}</RouterProvider>
    </React.Fragment>
  );
};
export default MainRouter;
