import { createTheme, Paper, Typography } from "@mui/material";
import React from "react";
import { ThemeProvider } from "styled-components";

export const ListComprehensionPython = () => {
  const defaultTheme = createTheme();
  const paperStyle = {
    p: 2,
    display: "flex",
    flexDirection: "column",
    height: "auto",
    backgroundColor: "#E6E6E6", // Set the background color to grey
    mt: 2,
  };
  return (
    <ThemeProvider theme={defaultTheme}>
      <Paper sx={paperStyle}>
        <Typography variant="h5">List Comprehension :</Typography>
        <ul>
          <li>
            In Python, list comprehensions serve the purpose of creating new
            lists by deriving elements from existing iterables such as tuples,
            strings, arrays, or other lists.
          </li>
          <li>
            The structure of a list comprehension involves enclosing an
            expression within brackets.
          </li>
          <li>
            This expression is then executed for each element, and a for loop is
            used to iterate over the elements of the iterable.
          </li>
          <li>
            Essentially, list comprehensions provide a concise way to generate
            lists based on specified conditions or transformations applied to
            the elements of another iterable.
          </li>
        </ul>
        <Typography variant="h6">Syntax of List Comprehension :</Typography>
        <Paper className="code">
          <pre>
            {`
[expression for item in list]

[expression for item in list if condition]

[expression if/else for item in list]

[expression if/else for item in list if condition]
`}
          </pre>
        </Paper>
        <Typography variant="h6">Examples of List Comprehension :</Typography>
        <Paper className="code">
            <pre>
                {
                    `
list = [for i in range(5)]
print(list)

output:
[1,2,3,4,5]

# list of odd numbers
list = [i for i in range(10) if i%2!=0]
print(list)

output:
[1,3,5,7,9]

# list with if else
list = [i if i%2==0 else "odd" for i in range(10)]
print(list)

output:
[0, 'odd', 2, 'odd', 4, 'odd', 6, 'odd', 8, 'odd']

# if else with nested loop

list = [i if i%2==0 else "odd" for i in range(10) if i%3==0]
print(list)

output:
[0, 'odd', 6, 'odd']
                    `
                }
            </pre>
        </Paper>
      </Paper>
    </ThemeProvider>
  );
};
