import {
  Box,
  createTheme,
  CssBaseline,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { ThemeProvider } from "styled-components";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import "./Code.css";

export const InputOutput = () => {
  const [tabvalue, settabvalue] = useState("1");
  const handleChange = (event, newValue) => {
    settabvalue(newValue);
  };

  const defaultTheme = createTheme();
  const paperStyle = {
    p: 2,
    display: "flex",
    flexDirection: "column",
    height: "auto",
    backgroundColor: "#E6E6E6", // Set the background color to grey
    mt: 2,
  };
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Grid container>
        <Typography variant="h4">
          Python | Output using print() function
        </Typography>
        <Paper sx={paperStyle}>
          <Typography variant="p">
            Python print() function prints the message to the screen or any
            other standard output device.
          </Typography>
          <ul>
            <li>
              In this example, three variables (integer, string, and float) have
              been created.
            </li>
            <li>
              The values of these variables are printed using the print()
              function in Python.
            </li>
          </ul>
          <TabContext value={tabvalue}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="print Without param print()" value="1" />
                <Tab label="print With param print()" value="2" />
                <Tab label="Practice Task" value="3" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <p>name ="John"</p>
              <p>age =23</p>
              <p>pi = 3.14</p>
              <p>print(name)</p>
              <p>print(age)</p>
              <p>print(pi)</p>
              <h6>OUTPUT:</h6>
              <p>Name: John</p>
              <p>Age: 30</p>
            </TabPanel>
            <TabPanel value="2">
              <Typography variant="body1">
                <p>name = "Alice"</p>
                <p>age = 25</p>
                <p>
                  print("Hello, my name is", name, "and I am", age, "years
                  old.")
                </p>
                <h6>OUTPUT:</h6>
                {`Hello, my name is Alice and I am 25 years old.`}
              </Typography>
            </TabPanel>
            <TabPanel value="3">
              <Typography variant="body1">
                <ul>
                  <li>Print your biodata with basic detail</li>
                  <li>
                    Declare variable firstName ans lastName and print in single
                    line
                  </li>
                </ul>
              </Typography>
            </TabPanel>
          </TabContext>

          <Typography variant="h6">Python print() Function Syntax</Typography>
          <Typography variant="body1">
            <ul>
              <li>
                <strong>Syntax:</strong>{" "}
                <code>
                  print(value(s), sep=' ', end='\n', file=file, flush=flush)
                </code>
              </li>
              <li>
                <strong>Parameters:</strong>
                <ul>
                  <li>
                    <code>value(s):</code> Any value, and as many as you like.
                    Will be converted to a string before printed
                  </li>
                  <li>
                    <code>sep='separator':</code> (Optional) Specify how to
                    separate the objects if there is more than one. Default: ' '
                  </li>
                  <li>
                    <code>end='end':</code> (Optional) Specify what to print at
                    the end. Default: '\n'
                  </li>
                  <li>
                    <code>file:</code> (Optional) An object with a write method.
                    Default: sys.stdout
                  </li>
                  <li>
                    <code>flush:</code> (Optional) A Boolean, specifying if the
                    output is flushed (True) or buffered (False). Default: False
                  </li>
                </ul>
              </li>
              <li>
                <strong>Return Type:</strong> It returns output to the screen.
              </li>
            </ul>
          </Typography>
          <Typography variant="p">
            Though it is not necessary to pass arguments in the print()
            function, it requires an empty parenthesis at the end that tells
            Python to execute the function rather than calling it by name. Now,
            let’s explore the optional arguments that can be used with the
            print() function.
          </Typography>
        </Paper>
        <Typography variant="h4">Taking input in Python</Typography>
        <Paper>
          <Typography variant="p">
            "Programs often need to talk to users, asking for information or
            showing results. Most programs use a dialog box to ask users for
            input. In Python, there are two built-in functions to get input from
            the keyboard."
          </Typography>
          <Typography variant="h6">input()</Typography>
          <ul>
            <li>
              <Typography variant="h6">Input Function in Python</Typography>
              <ul>
                <li>
                  This function first takes input from the user and converts it
                  into a string.
                </li>
                <li>
                  The type of the returned object is always `
                  <code>{"<class 'str'> "}</code>`.
                </li>
                <li>
                  It does not evaluate the expression; it just returns the
                  complete statement as a string.
                </li>
                <li>
                  For example, Python provides a built-in function called{" "}
                  <code>input</code> which takes input from the user.
                </li>
                <li>
                  When the <code>input</code> function is called, it stops the
                  program and waits for the user’s input.
                </li>
                <li>
                  When the user presses enter, the program resumes and returns
                  what the user typed.
                </li>
              </ul>
            </li>
          </ul>
          <Paper sx={paperStyle} className="code">
            # Python program showing <br />
            # a use of input()
            <br />
            val = input("Enter your value: ")
            <br />
            print(val)
            <br />
          </Paper>
          <ul>
            <li>
              <Typography variant="h6">
                Behavior of the input() Function
              </Typography>
              <ul>
                <li>
                  When the <code>input()</code> function is executed, the
                  program flow stops, and it waits until the user provides
                  input.
                </li>
                <li>
                  The text or message displayed on the output screen, asking the
                  user to enter a value (commonly known as the prompt), is
                  optional.
                </li>
                <li>
                  Regardless of what you enter as input, the{" "}
                  <code>input()</code> function always treats it as a string.
                </li>
                <li>
                  Even if you enter a number, it gets converted into a string.
                </li>
                <li>
                  If you intend to use the input as an integer, you need to
                  explicitly convert it in your code using a process called
                  typecasting.
                </li>
              </ul>
            </li>
          </ul>
          <Paper sx={paperStyle} className="code">
            # Program to check input <br />
            # type in Python
            <br />
            num = input ("Enter number :")
            <br />
            print(num)
            <br />
            name1 = input("Enter name : ")
            <br />
            print(name1)
            <br />
            # Printing type of input value
            <br />
            print ("type of number", type(num))
            <br />
            print ("type of name", type(name1))
            <br />
            <br />
            <Typography variant="h6">OUTPUT:</Typography>
            <br />
            <Typography variant="p">
              Enter number : 10
              <br />
              10
              <br />
              Enter name : John
              <br />
              John
              <br />
              type of number &lt;class 'str'&gt;
              <br />
              type of name &lt;class 'str'&gt;
            </Typography>
          </Paper>

          <Typography variant="h6">Taking integer input in Python</Typography>
          <Typography variant="p">
            Typecasting the input to Integer: There might be conditions when you
            might require integer input from the user/Console, the following
            code takes two input(integer/float) from the console and typecasts
            them to an integer then prints the sum.
          </Typography>
          <Paper sx={paperStyle} className="code">
            # input <br />
            num1 = int(input())
            <br />
            num2 = int(input())
            <br />
            # printing the sum in integer
            <br />
            print(num1 + num2)
          </Paper>
          <Typography variant="h6">Taking float input in Python</Typography>
          <Typography variant="p">
            Typecasting the input to Float: There might be conditions when you
            might require float input from the user/Console, the following
            code takes two input(float) from the console and typecasts
            them to an integer then prints the sum.
          </Typography>
          <Paper sx={paperStyle} className="code">
            # input <br />
            num1 = float(input())
            <br />
            num2 = float(input())
            <br />
            # printing the sum in integer
            <br />
            print(num1 + num2)
          </Paper>
        </Paper>
      </Grid>
    </ThemeProvider>
  );
};
