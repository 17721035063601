import { createTheme, Grid, Paper, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import { ThemeProvider } from "styled-components";
import "./Code.css";
export const Operators = () => {
  const defaultTheme = createTheme();
  const paperStyle = {
    p: 2,
    display: "flex",
    flexDirection: "column",
    height: "auto",
    backgroundColor: "#E6E6E6", // Set the background color to grey
    mt: 2,
  };
  const arithColumns = [
    {
      field: "Operator",
      headerName: "Operator",
      width: 150,
    },
    {
      field: "Description",
      headerName: "Description",
      width: 500,
    },
    {
      field: "Syntax",
      headerName: "Synatx",
      width: 500,
    },
  ];
  const arithRows = [
    {
      id: 1,
      Operator: "+",
      Description: "Adds two operands",
      Syntax: "x + y",
    },
    {
      id: 2,
      Operator: "-",
      Description: "Subtracts second operand from the first",
      Syntax: "x - y",
    },
    {
      id: 3,
      Operator: "*",
      Description: "Multiplies both operands",
      Syntax: "x * y",
    },
    {
      id: 4,
      Operator: "/",
      Description: "Divides numerator by de-numerator",
      Syntax: "x / y",
    },
    {
      id: 5,
      Operator: "%",
      Description:
        "Modulus Operator and remainder of after an integer division",
      Syntax: "x % y",
    },
    {
      id: 6,
      Operator: "**",
      Description: "Exponentiation (power)",
      Syntax: "x ** y",
    },
    {
      id: 7,
      Operator: "//",
      Description: "Floor division (quotient)",
      Syntax: "x // y",
    },
  ];

  const compcolumns = [
    { field: "operator", headerName: "Operator", flex: 1 },
    { field: "description", headerName: "Description", flex: 2 },
    { field: "syntax", headerName: "Syntax", flex: 2 },
  ];

  const comprows = [
    { id: 1, operator: ">", description: "Greater than", syntax: "x > y" },
    { id: 2, operator: "<", description: "Less than", syntax: "x < y" },
    { id: 3, operator: "==", description: "Equal to", syntax: "x == y" },
    { id: 4, operator: "!=", description: "Not equal to", syntax: "x != y" },
    {
      id: 5,
      operator: ">=",
      description: "Greater than or equal to",
      syntax: "x >= y",
    },
    {
      id: 6,
      operator: "<=",
      description: "Less than or equal to",
      syntax: "x <= y",
    },
  ];

  const bitcolumns = [
    { field: "operator", headerName: "Operator", flex: 1 },
    { field: "description", headerName: "Description", flex: 1 },
    { field: "syntax", headerName: "Syntax", flex: 1 },
  ];

  const bitrows = [
    { id: 1, operator: "&", description: "Bitwise AND", syntax: "x & y" },
    { id: 2, operator: "|", description: "Bitwise OR", syntax: "x | y" },
    { id: 3, operator: "~", description: "Bitwise NOT", syntax: "~x" },
    { id: 4, operator: "^", description: "Bitwise XOR", syntax: "x ^ y" },
    {
      id: 5,
      operator: ">>",
      description: "Bitwise right shift",
      syntax: "x >> y",
    },
    {
      id: 6,
      operator: "<<",
      description: "Bitwise left shift",
      syntax: "x << y",
    },
  ];

  const logcolumns = [
    { field: "operator", headerName: "Operator", flex: 1 },
    { field: "description", headerName: "Description", flex: 2 },
    { field: "syntax", headerName: "Syntax", flex: 2 },
  ];
  const logirows = [
    { id: 1, operator: "and", description: "Logical AND", syntax: "x and y" },
    { id: 2, operator: "or", description: "Logical OR", syntax: "x or y" },
    { id: 3, operator: "not", description: "Logical NOT", syntax: "not x" },
  ];

  const asgncolumns = [
    { field: "operator", headerName: "Operator", flex: 1 },
    { field: "description", headerName: "Description", flex: 2 },
    { field: "syntax", headerName: "Syntax", flex: 2 },
  ];

  const asgnrows = [
    {
      id: 1,
      operator: "=",
      description:
        "Assign the value of the right side to the left side operand",
      syntax: "x = y + z",
    },
    {
      id: 2,
      operator: "+=",
      description:
        "Add AND: Add right-side operand with left-side operand and then assign to left operand",
      syntax: "a += b (a = a + b)",
    },
    {
      id: 3,
      operator: "-=",
      description:
        "Subtract AND: Subtract right operand from left operand and then assign to left operand",
      syntax: "a -= b (a = a - b)",
    },
    {
      id: 4,
      operator: "*=",
      description:
        "Multiply AND: Multiply right operand with left operand and then assign to left operand",
      syntax: "a *= b (a = a * b)",
    },
    {
      id: 5,
      operator: "/=",
      description:
        "Divide AND: Divide left operand with right operand and then assign to left operand",
      syntax: "a /= b (a = a / b)",
    },
    {
      id: 6,
      operator: "%=",
      description:
        "Modulus AND: Takes modulus using left and right operands and assign the result to left operand",
      syntax: "a %= b (a = a % b)",
    },
    {
      id: 7,
      operator: "//=",
      description:
        "Divide (floor) AND: Divide left operand with right operand and then assign the value(floor) to left operand",
      syntax: "a //= b (a = a // b)",
    },
    {
      id: 8,
      operator: "**=",
      description:
        "Exponent AND: Calculate exponent (raise power) value using operands and assign value to left operand",
      syntax: "a **= b (a = a ** b)",
    },
    {
      id: 9,
      operator: "&=",
      description:
        "Performs Bitwise AND on operands and assign value to left operand",
      syntax: "a &= b (a = a & b)",
    },
    {
      id: 10,
      operator: "|=",
      description:
        "Performs Bitwise OR on operands and assign value to left operand",
      syntax: "a |= b (a = a | b)",
    },
    {
      id: 11,
      operator: "^=",
      description:
        "Performs Bitwise XOR on operands and assign value to left operand",
      syntax: "a ^= b (a = a ^ b)",
    },
    {
      id: 12,
      operator: ">>=",
      description:
        "Performs Bitwise right shift on operands and assign value to left operand",
      syntax: "a >>= b (a = a >> b)",
    },
    {
      id: 13,
      operator: "<<=",
      description:
        "Performs Bitwise left shift on operands and assign value to left operand",
      syntax: "a <<= b (a = a << b)",
    },
  ];
  return (
    <ThemeProvider theme={defaultTheme}>
      <Paper sx={paperStyle}>
        <Typography variant="h6">Operators in Python</Typography>
        <Typography variant="p">
          In Python programming, Operators in general are used to perform
          operations on values and variables. <br />
          These are standard symbols used for the purpose of logical and
          arithmetic operations. In this article, we will look into different
          types of Python operators.
        </Typography>
        <ul>
          <li>OPERATORS: These are the special symbols. Eg- + , * , /, etc.</li>
          <li>OPERAND: It is the value on which the operator is applied.</li>
        </ul>
        <ul>
          <li>
            <Typography variant="h6">Types of Operators in Python</Typography>
            <ul>
              <li>Arithmetic Operators</li>
              <li>Comparison Operators</li>
              <li>Logical Operators</li>
              <li>Bitwise Operators</li>
              <li>Assignment Operators</li>
              <li>Identity Operators and Membership Operators</li>
            </ul>
          </li>
        </ul>
        <Typography variant="h6">Arithmetic Operators</Typography>
        <Typography variant="p">
          Python Arithmetic operators are used to perform basic mathematical
          operations like addition, subtraction, multiplication, and division.
          <br />
          <br />
          In Python 3.x the result of division is a floating-point while in
          Python 2.x division of 2 integers was an integer. To obtain an integer
          result in Python 3.x floored (// integer) is used.
        </Typography>
        <Paper>
          <DataGrid
            rows={arithRows}
            columns={arithColumns}
            getRowId={(row) => row.id}
            pagination={false}
          ></DataGrid>
        </Paper>
        <Typography mt={2} variant="h6">
          Arithmetic Operators With Addition, Subtraction, Multiplication,
          Modulo and Power
        </Typography>
        <Paper className="code">
          # Examples of Arithmetic Operator <br />
          a = 9<br />
          b = 4<br />
          <br />
          # Addition of numbers
          <br />
          add = a + b<br />
          <br />
          # Subtraction of numbers
          <br />
          sub = a - b<br />
          <br />
          # Multiplication of number
          <br />
          mul = a * b<br />
          <br />
          # Modulo of both number
          <br />
          mod = a % b<br />
          <br />
          # Power
          <br />
          p = a ** b<br />
          # print results
          <br />
          print(add)
          <br />
          print(sub)
          <br />
          print(mul)
          <br />
          print(mod)
          <br />
          print(p)
          <br />
          <br />
          Output:
          <br />
          13
          <br />
          5<br />
          36
          <br />
          1<br />
          6561
        </Paper>
        <Typography variant="h6">Comparison Operators</Typography>
        <DataGrid
          rows={comprows}
          columns={compcolumns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
        />
        <Paper className="code">
          {/* Examples of Relational Operators */}
          a = 13
          <br />
          b = 33
          <br />
          <br />
          {/* 𝑎 > 𝑏 is False */}
          print(a &gt; b)
          <br />
          {/* 𝑎 < 𝑏 is True */}
          print(a &lt; b)
          <br />
          {/* 𝑎 == 𝑏 is False */}
          print(a == b)
          <br />
          {/* 𝑎 != 𝑏 is True */}
          print(a != b)
          <br />
          {/* 𝑎 ≥ 𝑏 is False */}
          print(a &gt;= b)
          <br />
          {/* 𝑎 ≤ 𝑏 is True */}
          print(a &lt;= b)
          <br />
          <br />
          Output :<br />
          False
          <br />
          True
          <br />
          False
          <br />
          True
          <br />
          False
          <br />
          True
        </Paper>
        <Typography variant="h6">Logical Operators</Typography>
        <Typography variant="p">
          Python Logical operators perform Logical AND, Logical OR, and Logical
          NOT operations. It is used to combine conditional statements.
        </Typography>
        <DataGrid
          rows={logirows}
          columns={logcolumns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
        />
        <Paper className="code">
          # Examples of Logical Operator
          <br />
          a = True
          <br />
          b = False
          <br />
          <br />
          # Print a and b is False
          <br />
          print(a and b)
          <br />
          <br />
          # Print a or b is True
          <br />
          print(a or b)
          <br />
          <br />
          # Print not a is False
          <br />
          print(not a)
          <br />
          <br />
          Output :<br />
          False
          <br />
          True
          <br />
          False
        </Paper>
        <Typography variant="h6">Bitwise Operators</Typography>
        <Typography variant="p">
          Python Bitwise operators act on bits and perform bit-by-bit
          operations. These are used to operate on binary numbers.
        </Typography>
        <DataGrid
          rows={bitrows}
          columns={bitcolumns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
        ></DataGrid>
        <Typography variant="h6">Bitwise Operators in Python</Typography>
        <Paper className="code">
          # Examples of Bitwise operators <br />
          a = 10
          <br />
          b = 4<br />
          <br />
          # Print bitwise AND operation
          <br />
          print(a & b)
          <br />
          <br />
          # Print bitwise OR operation
          <br />
          print(a | b)
          <br />
          <br />
          # Print bitwise NOT operation
          <br />
          print(~a)
          <br />
          # print bitwise XOR operation
          <br />
          print(a ^ b)
          <br />
          <br />
          # print bitwise right shift operation
          <br />
          print(a &lt;&lt; 2)
          <br />
          <br />
          # print bitwise left shift operation
          <br />
          print(a &gt;&gt; 2)
          <br />
          <br />
          Output :<br />
          <br />
          0<br />
          14
          <br />
          -11
          <br />
          14
          <br />
          2<br />
          40
          <br />
        </Paper>
        <Grid>
          <Typography variant="h6">Assignment Operators in Python</Typography>
          <Typography>
            Python Assignment operators are used to assign values to the
            variables.
          </Typography>
          <Typography>
            Let's see an example of Assignment Operators in Python:
          </Typography>
          <Paper className="code">
            <pre>
              {`# Examples of Assignment Operators
a = 10

# Assign value
b = a
print(b)

# Add and assign value
b += a
print(b)

# Subtract and assign value
b -= a
print(b)

# Multiply and assign
b *= a
print(b)

# Bitwise left shift operator
b <<= a
print(b)`}
            </pre>

            <Typography variant="h6">Examples Output</Typography>
            <pre>
              {`10
20
10
100
102400`}
            </pre>
          </Paper>
          <Grid style={{ height: 800, width: "100%" }}>
            <DataGrid
              rows={asgnrows}
              columns={asgncolumns}
              pageSize={5}
              rowsPerPageOptions={[10]}
              disableSelectionOnClick
            />
          </Grid>
        </Grid>
        <Typography variant="h6">Identity Operators in Python :</Typography>
        <Typography variant="p">
          In Python, is and is not are the identity operators both are used to
          check if two values are located on the same part of the memory. Two
          variables that are equal do not imply that they are identical.
        </Typography>
        <Grid>
          <pre>
            is True if the operands are identical <br />
            is not True if the operands are not identical
          </pre>
        </Grid>
        <Typography variant="h6">
          Example Identity Operators in Python
        </Typography>
        <Paper className="code">
          <pre>
            {`a = 10 
b = 20
c = a
print(a is not b)
print(a is c)`}
            {`
Output :

True
True */
`}
          </pre>
        </Paper>
        <Typography variant="h6">Membership Operators in Python</Typography>
        <Typography variant="p">
          In Python, in and not in are the membership operators that are used to
          test whether a value or variable is in a sequence.
        </Typography>
        <Paper className="code">
          <br />
          in  True if value is found in the sequence <br />
          not in True if value is not found in the sequence
        </Paper>
      </Paper>
    </ThemeProvider>
  );
};
