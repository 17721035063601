import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Accordion,
  Box,
  createTheme,
  Grid,
  Paper,
  Tab,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { ThemeProvider } from "styled-components";
import "./Code.css";

export const ControlFlowTask = () => {
  const defaultTheme = createTheme();
  const [tabvalue, settabvalue] = useState("1");
  const handleChange = (event, newValue) => {
    settabvalue(newValue);
  };
  const paperStyle = {
    p: 2,
    display: "flex",
    flexDirection: "column",
    height: "auto",
    backgroundColor: "#E6E6E6", // Set the background color to grey
    mt: 2,
  };
  return (
    <ThemeProvider theme={defaultTheme}>
      <Paper sx={paperStyle}>
        <TabContext value={tabvalue}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="if else Tasks" value="1" />
              <Tab label="For loop Task" value="2" />
              <Tab label="While loop Task" value="3" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <ul style={{ color: "blue" }}>
              <li>
                <strong>Easy:</strong> Write a program to check whether a number
                is even or odd using if-else statements.
              </li>
              <li>
                <strong>Easy:</strong> Write a program to check whether a number
                is positive or negative using if-else statements.
              </li>
              <li>
                <strong>Easy:</strong> Write a program to check whether a number
                is divisible by 5 and 11 or not using if-else statements.
              </li>
              <li>
                <strong>Easy:</strong> Write a program to check whether a number
                is a leap year or not using if-else statements.
              </li>
              <li>
                <strong>Easy:</strong> Write a program to check whether a
                character is an alphabet or not using if-else statements.
              </li>
              <li>
                <strong>Moderate:</strong> Write a program to input any alphabet
                and check whether it is a vowel or consonant using if-else
                statements.
              </li>
              <li>
                <strong>Moderate:</strong> Write a program to input any
                character and check whether it is an alphabet, digit, or special
                character using if-else statements.
              </li>
              <li>
                <strong>Moderate:</strong> Write a program to check whether a
                character is an uppercase or lowercase alphabet using if-else
                statements.
              </li>
              <li>
                <strong>Moderate:</strong> Write a program to input the week
                number and print the week day using if-else statements.
              </li>
              <li>
                <strong>Moderate:</strong> Write a program to input the month
                number and print the number of days in that month using if-else
                statements.
              </li>
              <li>
                <strong>Hard:</strong> Write a program to input angles of a
                triangle and check whether the triangle is valid or not using
                if-else statements.
              </li>
              <li>
                <strong>Hard:</strong> Write a program to input all sides of a
                triangle and check whether the triangle is valid or not using
                if-else statements.
              </li>
              <li>
                <strong>Hard:</strong> Write a program to check whether the
                triangle is an equilateral, isosceles, or scalene triangle using
                if-else statements.
              </li>
            </ul>
          </TabPanel>
          <TabPanel value="2">
            <Typography variant="h6">For loop Task</Typography>
            <ul style={{ color: "blue" }}>
              <li>
                <strong>Easy:</strong> Write a programme to print table of input
                number.
              </li>
              <li>
                <strong>Easy:</strong> Write a programme to print sum of first n
                natural numbers.
              </li>
              <li>
                <strong>Easy:</strong> Write a programme to print sum of first n
                even numbers.
              </li>
              <li>
                <strong>Easy:</strong> Write a programme to print sum of first n
                odd numbers.
              </li>
              <li>
                <strong>Easy:</strong> Write a programme to print sum of first n
                numbers which are divisible by 3 or 5.
              </li>
              <li>
                <strong>Easy:</strong> Display numbers from -10 to -1 using for
                loop
              </li>
              <li>
                <strong>Easy:</strong> Find the factorial of a given number
              </li>
            </ul>
            <Typography variant="h6">For loop Patterns :</Typography>
            <br />
            <Grid container spacing={2} style={{ overflow: "scroll" }}>
              <Grid container spacing={2} m={2}>
                <Grid item xs={3}>
                  <Paper elevation={3} className="paper-box">
                    <pre>
                      {`
*
* *
* * *
* * * *
* * * * *                            
                            `}
                    </pre>
                  </Paper>
                </Grid>
                <Grid item xs={3}>
                  <Paper elevation={3} className="paper-box">
                    <pre>
                      {`
* * * * *
* * * *
* * *
* *
*
`}
                    </pre>
                  </Paper>
                </Grid>
                <Grid item xs={3}>
                  <Paper elevation={3} className="paper-box">
                    <pre>
                      {`
    *
   * *                                                        
  * * *
 * * * *
* * * * *
`}
                    </pre>
                  </Paper>
                </Grid>
                <Grid item xs={3}>
                  <Paper elevation={3} className="paper-box">
                    <pre>
                      {`
1
1 2
1 2 3
1 2 3 4
1 2 3 4 5`}
                    </pre>
                  </Paper>
                </Grid>
              </Grid>
              <Grid container spacing={2} m={2}>
                <Grid item xs={3}>
                  <Paper elevation={3} className="paper-box">
                    <pre>
                      {`
1
1 0
1 0 1
1 0 1 0
1 0 1 0 1                            
                            `}
                    </pre>
                  </Paper>
                </Grid>
                <Grid item xs={3}>
                  <Paper elevation={3} className="paper-box">
                    <pre>
                      {`
1
2 3
4 5 6
7 8 9 10
11 12 13 14 15                            
                            `}
                    </pre>
                  </Paper>
                </Grid>
                <Grid item xs={3}>
                  <Paper elevation={3} className="paper-box">
                    <pre>{`
* * * * *
 * * * *
  * * *
   * *
    *                             
                        `}</pre>
                  </Paper>
                </Grid>
                <Grid item xs={3}>
                  <Paper elevation={3} className="paper-box">
                    <pre>
                      {`
    *
   * *
  * * *                                                         
* * * * *
 * * * *
  * * *
   * *
    *   
                            `}
                    </pre>
                  </Paper>
                </Grid>
              </Grid>
              <Grid container spacing={2} m={2}>
                <Grid item xs={3}>
                  <Paper elevation={3} className="paper-box">
                    <pre>
                      {`
* * * * *
*       *                    
*       *
*       *   
* * * * *                            `}
                    </pre>
                  </Paper>
                </Grid>
                <Grid item xs={3}>
                  <Paper elevation={3} className="paper-box">
                    <pre>
                      {`
    * 
   * *
  *   *
 *     *
* * * * *

                            `}
                    </pre>
                  </Paper>
                </Grid>
                <Grid item xs={3}>
                  <Paper elevation={3} className="paper-box">
                    <pre>
                      {`
    1 
   1 1
  1 2 1
 1 3 3 1                                
                            `}
                    </pre>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="3">
            <Typography variant="h6">While loop Task :</Typography>
            <ul>
              <li>
                <strong>Easy:</strong> Write a program to print Sum of digit of
                a number. eg. 1234 = 1+2+3+4 = 10
              </li>
              <li>
                <strong>Easy:</strong> Write a program to print reverse of a
                number. eg. 1234 = 4321
              </li>
              <li>
                <strong>Easy:</strong> Write a program to print sum of first n
                natural numbers.
              </li>
              <li>
                <strong>Easy:</strong> Write a program to print sum of first n
                even numbers.
              </li>
              <li>
                <strong>Easy:</strong> Write a program to print sum of first n
                odd numbers.
              </li>
              <li>
                <strong>Easy:</strong> Write a program to check whether a number
                is palindrome or not.
              </li>
              <li>
                <strong>Easy:</strong> Write a program to check whether a number
                is Armstrong or not.
              </li>
              <li>
                <strong>Easy:</strong> Write a program to check whether a number
                is prime or not.
              </li>
              <li>
                <strong>Easy:</strong> Write a program to print all prime
                numbers between 1 to n.
              </li>
              <li>
                <strong>Easy:</strong> Write a program to print all Armstrong
              </li>
              <li>
                <strong>Hard:</strong> Write a program to print Fibonacci series
              </li>
              <li>
                <strong>Hard:</strong> Write a program for LCM of two numbers.
              </li>
              <li>
                <strong>Hard:</strong> Write a program for HCF of two numbers.
              </li>
              <li>
                <strong>Hard:</strong> Write a program to print all factors of a
                number.
              </li>
              <li>
                <strong>Hard: </strong> Write a program to enter the numbers
                till the user wants and at the end it should display the count
                of positive, negative and zeros entered.
              </li>
              <li>
                <strong>Hard:</strong> Write a programme to accept 10 numbers
                and display the sum of positive numbers and negative numbers
                separately.
              </li>
            </ul>
          </TabPanel>
        </TabContext>
      </Paper>
    </ThemeProvider>
  );
};
