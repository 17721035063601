import { createTheme, Paper, ThemeProvider, Typography } from "@mui/material";
import React from "react";

export const FunctionPythonLambda = () => {
  const defaultTheme = createTheme();
  const paperStyle = {
    p: 2,
    display: "flex",
    flexDirection: "column",
    height: "auto",
    backgroundColor: "#E6E6E6", // Set the background color to grey
    mt: 2,
  };
  return (
    <ThemeProvider theme={defaultTheme}>
      <Paper sx={paperStyle}>
        <Typography variant="h6">Lambda Functions in Python :</Typography>
        <Typography variant="p">
          In Python, an anonymous function is a function without a name.
          Normally, when we define functions, we use the "def" keyword. However,
          when creating anonymous functions, we use the "lambda" keyword. So, in
          simple terms, an anonymous function in Python is a function that
          doesn't have a name, and it's created using the "lambda" keyword.
        </Typography>

        <typography variant="h6">
          Syntax of Lambda Function in python :
        </typography>
        <Paper className="code">
          <pre>{`lambda arguments: expression`}</pre>
        </Paper>
        <Typography variant="h6">
          Example of Lambda Function with Argument without return type :
        </Typography>
        <Paper className="code">
          <pre>
            {`# Program to show the use of lambda functions
double = lambda x: print(x*2)

# Output: 10
double(5)`}
          </pre>
        </Paper>
        <Typography variant="h6">
          Example of Lambda Function with Argument with return type :
        </Typography>
        <Paper className="code">
          <pre>
            {`# Program to show the use of lambda functions
double = lambda x: x * 2
print(double(5))

# Output:
# 10`}
          </pre>
        </Paper>
        <Typography variant="h6">
          Example of Lambda Function without Argument with return type :
        </Typography>
        <Paper className="code">
          <pre>
            {`
# Program to show the use of lambda functions
double = lambda : 5 * 2
print(double())

# Output:
# 10
`}
          </pre>
        </Paper>
        <Typography variant="h6">
          The lambda function gets more helpful when used inside a function
        </Typography>
        <Paper className="code">
          <pre>
            {`
l = ["1", "2", "9", "0", "-1", "-2"]
# sort list[str] numerically using sorted()
# and custom sorting key using lambda
print("Sorted numerically:",
      sorted(l, key=lambda x: int(x)))
 
# filter positive even numbers
# using filter() and lambda function
print("Filtered positive even numbers:", 
      list(filter(lambda x: not (int(x) % 2 == 0 and int(x) > 0), l)))
 
# added 10 to each item after type and
# casting to int, then convert items to string again
print("Operation on each item using lambda and map()",
list(map(lambda x: str(int(x) + 10), l)))            

Output
Sorted numerically: ['-2', '-1', '0', '1', '2', '9']
Filtered positive even numbers: ['1', '9', '0', '-1', '-2']
Operation on each item using lambda and map() ['11', '12', '19', '10', '9', '8']
            `}
          </pre>
        </Paper>
      </Paper>
    </ThemeProvider>
  );
};
