import React from "react";
import MainRouter from "./Components/Router/Routers";
import Header from "./Layouts/Header";
import MiniHeader from "./Layouts/MiniHeader";
import SideBar from "./Layouts/SideBar";

const App = () => {
  return (
    <div>
      <MainRouter />
    </div>
  );
};

export default App;
